import React from 'react'
import Banner from '../Components/Banner/Banner'
import Step from '../Components/Step/Step'
import Product from '../Components/Product/Product'
import Category from '../Components/Category/Category'
import Download from '../Components/Download/Download'
import Sponsor from '../Components/Sponsor/Sponsor'
import Mission from '../Components/Mission/Mission'
import MyReview from './MyReview'

function Home() {
  return (
    <div>   
      
       <Banner/>
       <Product/>
       
       <Step/>
       {/* <Mission/> */}
       <Sponsor/>
       <Category/>
       <MyReview/>
       <Download/>
 
    </div>
  )
}

export default Home