import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItemCountNav, setCartItemCount] = useState(0); // Initialize state to 0
  const [isLoggedIn, setIsLoggedIn] = useState(false);  // Track login state
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  // Function to update cart count
  const updateCartCount = (newCount) => {
    setCartItemCount(newCount);
  };

  // Check if the user is logged in
  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);  // User is logged in, trigger API call
    } else {
      setIsLoggedIn(false); // User is logged out
    }
  }, [token]);

  // Fetch cart items and update count
  useEffect(() => {
    if (!isLoggedIn) {
      return; // Don't fetch cart items if not logged in
    }

    const fetchCartItems = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}cart/count`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );

        const data = await response.json();
            // console.log(data.data.totalQuantity,'stt')
        if (response.ok && data?.status === 200 && data?.data?.totalQuantity !== undefined) {
          const totalItems = data.data.totalQuantity || 0;  // Use the API count, default to 0 if undefined
          updateCartCount(totalItems);  // Update the count in state
        } else {
          // console.error("Failed to fetch cart items:", data?.message || "Unknown error");
          
        }
      } catch (error) {
        // console.error("Error fetching cart items:", error);
      }
    };

    fetchCartItems();
  }, [isLoggedIn, token]);  // Re-fetch if login status or token changes
// console.log(cartItemCountNav,"cartItemCountNav")
  return (
    <CartContext.Provider value={{ cartItemCountNav, updateCartCount }}>
      {children}
    </CartContext.Provider>
  );
};
