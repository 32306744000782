import React from 'react';
import balancecoin from '../Assets/Icon/balance-coin.svg';

function ResultValue({ balance, total_amount }) {
  return (
    <div className='result-strip d-flex-center gap-2'>
      <img src={balancecoin} alt='logo' data-aos="fade-right" data-aos-delay="300" height="30px" width="30px" />
      <p className='f-18' data-aos="fade-left" data-aos-delay="600">
        Your current balance is <strong className='f-20 f-weight-600'>Rs. {balance}</strong>.<br/> You can use this amount for various transactions.<br/>
        Your Total balance <strong className='f-20 f-weight-600'>Rs.{total_amount}</strong>
      </p>
      
    </div>
  );
}

export default ResultValue;
