// import React from "react";
// import { useLocation, useParams } from "react-router-dom";
// import "./Product.css";
// import SearchBar from "../../Searchbar/Searchbar";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFilter } from "@fortawesome/free-solid-svg-icons";
// import { useInView } from "react-intersection-observer";
// import { Link } from "react-router-dom";

// function Product() {
//   const [products, setProducts] = React.useState([]);
//   const [offset, setOffset] = React.useState(0);
//   const [hasMore, setHasMore] = React.useState(true);
//   const [searchQuery, setSearchQuery] = React.useState("");
//   const [isEmpty, setIsEmpty] = React.useState(false);
//   const limit = 4;

//   const { ref, inView } = useInView({
//     threshold: 1,
//     triggerOnce: false,
//   });

//   const { id } = useParams(); // Get category ID from URL
//   const location = useLocation(); // Get location to retrieve passed state
//   const { categoryName } = location.state || { categoryName: "Products" }; // Default to "Products" if not passed

//   const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;

//   const fetchProductData = async (newOffset, query) => {
//     try {
//       const endpoint =
//         query.trim() === ""
//           ? `${baseUrl}product/category?category_id=${id}&offset=${newOffset}&limit=${limit}`
//           : `${baseUrl}product/search?offset=${newOffset}&limit=${limit}&search=${encodeURIComponent(
//               query
//             )}`;

//       const response = await fetch(endpoint, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//         },
//       });

//       const jsonData = await response.json();

//       if (jsonData && jsonData.status === 200) {
//         const productJson = jsonData.data?.products || [];
//         if (productJson.length === 0 && newOffset === 0) {
//           setIsEmpty(true);
//           setHasMore(false);
//         } else {
//           setProducts((prevProducts) => [...prevProducts, ...productJson]);
//           if (productJson.length < limit) {
//             setHasMore(false);
//           }
//         }
//       }
//     } catch (err) {
//       console.error("Error fetching products:", err);
//     }
//   };

//   React.useEffect(() => {
//     fetchProductData(0, searchQuery);
//   }, [searchQuery, id]);

//   React.useEffect(() => {
//     if (inView && hasMore) {
//       fetchProductData(offset, searchQuery);
//       setOffset((prevOffset) => prevOffset + 1);
//     }
//   }, [inView, hasMore]);

//   const handleSearch = (query) => {
//     setSearchQuery(query);
//     setProducts([]);
//     setOffset(0);
//     setHasMore(true);
//     setIsEmpty(false);
//   };

//   return (
//     <div className="product-list p-80 text-center bg-fb">
//       <div className="container">
//         <h3 className="title">{categoryName}</h3>{" "}
//         {/* Display passed category name */}
//         <div className="row">
//           <div className="col-md-10 mb-4">
//             <SearchBar
//               searchtext="Search Here"
//               searchtype="text"
//               onSearch={handleSearch}
//             />
//           </div>
//           <div className="col-md-2 filter-mob">
//             <button className="common-btn d-flex">
//               <FontAwesomeIcon icon={faFilter} className="text-white" />
//               Filter
//             </button>
//           </div>
//           {isEmpty ? (
//             <div className="no-data">No data available</div>
//           ) : (
//             <>
//               {products.map((item, index) => (
//                 <div
//                   key={index}
//                   className="col-md-6 col-lg-3 col-sm-12 gift-card"
//                   data-aos="fade-right"
//                   data-aos-delay="300"
//                 >
//                   <Link
//                     to={`/productdetail/${item.sku}`}
//                     className="card d-block border-0"
//                   >
//                     <h3 className="f-20 mb-2">{item?.name}</h3>
//                     <i className="product-image">
//                       <img
//                         src={item.thumbnail}
//                         alt="product"
//                         height="100%"
//                         width="100%"
//                       />
//                     </i>
//                   </Link>
//                 </div>
//               ))}
//               {hasMore && (
//                 <div ref={ref} style={{ height: "100px" }}>
//                   <p>Loading more products...</p>
//                 </div>
//               )}
//             </>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Product;



import React from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import Slider from "react-slick";
import "./Product.css";
import SearchBar from "../../Searchbar/Searchbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useInView } from "react-intersection-observer";

// Import category images
import dining from '../../Assets/Category/dinning.svg';
import living from '../../Assets/Category/living.svg';
import fashion from '../../Assets/Category/fashion.svg';
import health from '../../Assets/Category/health.svg';
import travel from '../../Assets/Category/travel.svg';
import electronic from '../../Assets/Category/electronic.svg';
import entertainment from '../../Assets/Category/entertainment.svg';
import jewelry from '../../Assets/Category/j.svg';

function Product() {
  const [products, setProducts] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(true);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [isEmpty, setIsEmpty] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState(""); // State for selected category
  const limit = 4;

  const { ref, inView } = useInView({ threshold: 1 });
  const { id } = useParams();
  const location = useLocation();
  const { categoryName } = location.state || { categoryName: "Products" };
  const queryParams = new URLSearchParams(location.search);
  const nameFromParams = queryParams.get("name");

  React.useEffect(() => {
    if (nameFromParams) setSelectedCategory(nameFromParams);
  }, [nameFromParams]);

  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;

  const fetchProductData = async (newOffset, query, category) => {
    try {
      const endpoint =
        query.trim() === ""
          ? `${baseUrl}product/category?category_id=${id}&offset=${newOffset}&limit=${limit}`
          : `${baseUrl}product/search?offset=${newOffset}&limit=${limit}&search=${encodeURIComponent(query)}&category_id=${category}`;

      const response = await fetch(endpoint, {
        headers: { "Content-Type": "application/json", Accept: "application/json" },
      });

      const jsonData = await response.json();

      if (jsonData && jsonData.status === 200) {
        const productJson = jsonData.data?.products || [];
        if (productJson.length === 0 && newOffset === 0) {
          setIsEmpty(true);
          setHasMore(false);
        } else {
          setProducts((prev) => [...prev, ...productJson]);
          if (productJson.length < limit) setHasMore(false);
        }
      }
    } catch (err) {
      // console.error("Error fetching products:", err);
    }
  };

  React.useEffect(() => {
    fetchProductData(0, searchQuery, selectedCategory);
  }, [searchQuery, id, selectedCategory]);

  React.useEffect(() => {
    if (inView && hasMore) {
      fetchProductData(offset, searchQuery, selectedCategory);
      setOffset((prev) => prev + 1);
    }
  }, [inView, hasMore]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    setProducts([]);
    setOffset(0);
    setHasMore(true);
    setIsEmpty(false);
  };

  const categories = {
    dining: { heading: 'Dining & Food', description: 'Explore dining options', img: dining, heading2: 'Dining' },
    fashion: { heading: 'Fashion & Beauty', description: 'Latest fashion trends', img: fashion, heading2: 'Fashion' },
    electronic: { heading: 'Electronics', description: 'Best electronics deals', img: electronic, heading2: 'Electronics' },
    entertainment: { heading: 'Entertainment', description: 'Fun and entertainment', img: entertainment, heading2: 'Entertainment' },
    health: { heading: 'Wellness & Health', description: 'Health and wellness products', img: health, heading2: 'Wellness' },
    travel: { heading: 'Travel', description: 'Discover new destinations', img: travel, heading2: 'Travel' },
    jewelry: { heading: 'Jewelry', description: 'Elegant jewelry collections', img: jewelry, heading2: 'Jewelry' },
    living: { heading: 'Home & Living', description: 'Home and living products', img: living, heading2: 'Home' },
  };

  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    loop: true,
    slidesToShow: 6,
    slidesToScroll: 3,
    autoplay: false,
    speed: 3000,
    
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 4, slidesToScroll: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 480, settings: { slidesToShow: 3, slidesToScroll: 1 } }
    ]
  };

  return (
    <div className="product-list p-80 text-center bg-fb">
      <Slider {...sliderSettings}>
        {Object.keys(categories).map((key, index) => {
          const isSelected = categories[key].heading2 === selectedCategory || categories[key].heading2 === categoryName;
          return (
            <div key={index} className="main-tab m-4 ">
              <Link
                to={`/categoryproduct/121?name=${categories[key].heading2}`}
                onClick={() => setSelectedCategory(categories[key].heading2)}
                className={`card px-2 border-0  d-flex-center m-2 ${isSelected ? "selected-card" : ""}`}
              >
                <div className="card-body ">
                  <img src={categories[key].img} alt={categories[key].heading} className="rounded  m-3" height="50px" width="50px" />
                  <h5 className="card-title f-14 f-weight-600">{categories[key].heading}</h5>
                  {/* <p className="card-text">{categories[key].description}</p> */}
                </div>
              </Link>
            </div>
          );
        })}
      </Slider>

      <div className="container">
        <h3 className="title mt-5">{selectedCategory || categoryName}</h3>
        <div className="row">
          <div className="col-md-10 mb-4">
            <SearchBar searchtext="Search Here" searchtype="text" onSearch={handleSearch} />
          </div>
          <div className="col-md-2 filter-mob">
            <button className="common-btn d-flex">
              <FontAwesomeIcon icon={faFilter} className="text-white" />
              Filter
            </button>
          </div>
          {isEmpty ? (
            <div className="no-data">No data available</div>
          ) : (
            <>
              {products.map((item, index) => (
                <div key={index} className="col-md-6 col-lg-3 col-sm-12 gift-card">
                  <Link to={`/productdetail/${item.sku}`} className="card d-block border-0">
                    <h3 className="f-20 mb-2">{item?.name}</h3>
                    <i className="product-image">
                      <img src={item.thumbnail} alt="product" height="100%" width="100%" />
                    </i>
                  </Link>
                </div>
              ))}
              {hasMore && <div ref={ref} style={{ height: "100px" }}><p>Loading more products...</p></div>}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Product;
