import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import banner from '../Assets/plus/banner.png';  // Default fallback banner image
import banner2 from '../Assets/plus/banner2.png'; // Another default fallback image

function PLusBanner() {
  const [banners, setBanners] = useState([]);  // State to store banners

  // Function to fetch banners from API
  const fetchBanners = async () => {
    const baseUrl = `${process.env.REACT_APP_BASE_API_URL}home/homeData`;  // Use the correct API endpoint

    try {
      const response = await fetch(baseUrl, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const jsonData = await response.json();
      if (jsonData && jsonData.status === 200) {
        // Assuming jsonData.data.gift_card_banners contains the banner images
        setBanners(jsonData.data?.gift_card_banners || []);  // Update state with fetched banners
      }
    } catch (err) {
      // console.error("Error fetching banners:", err);
    }
  };

  useEffect(() => {
    fetchBanners();  // Fetch banners when the component is mounted
  }, []);  // Empty dependency array to run only once on mount

  // Slider configuration
  const plusslider = {
    dots: true,          // Show dots for navigation
    infinite: true,      // Infinite scrolling
    slidesToShow: 1,     // Show only one slide at a time
    slidesToScroll: 1,   // Scroll one slide at a time
    autoplay: true,      // Autoplay enabled
    speed: 3000,         // Speed for transition between slides
    autoplaySpeed: 2000, // Time before the next slide starts
    cssEase: "linear",   // Smooth transition effect
  };

  // Default images if banners are empty
  const defaultBanners = [
    { media_url: banner },    // First default image
    { media_url: banner2 },   // Second default image
  ];

  // Use the fetched banners or fallback to default banners
  const bannersToDisplay = banners.length > 0 ? banners : defaultBanners;

  return (
    <div className="banner plus-b p-30">
      <div className="container">
        {bannersToDisplay.length === 1 ? (
          // Render a single banner image if there's only one
          <div className="single-banner">
            <img
              src={bannersToDisplay[0].media_url}  // Banner image URL from API or default
              alt="Single Banner"  // Alt text for accessibility
              height="100%"  // Ensures image takes full height of its container
              width="100%"   // Ensures image takes full width of its container
              className="slider1"  // Optional class for styling
            />
          </div>
        ) : (
          // Render the slider for multiple banners
          <Slider {...plusslider}>
            {bannersToDisplay.map((bannerItem, index) => (
              <div key={index}>
                <div className="p-3">
                  <img
                    src={bannerItem.media_url}  // Banner image URL from API or default
                    alt={`Banner ${index + 1}`}  // Alt text for accessibility
                    height="100%"  // Ensures image takes full height of its container
                    width="100%"   // Ensures image takes full width of its container
                    className="slider1"  // Optional class for styling
                  />
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
}

export default PLusBanner;
