
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Offers.css";
import Strip from "./Strip";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../Preloader/Preloader";

const Offers = () => {
  const [offerData, setOfferData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true); // Track if more offers are available
  const limit = 10; // Number of offers per request
  const navigate = useNavigate();

  // Fetch offers function
  const fetchOffers = async () => {
    if (loading || !hasMore) return; // Prevent duplicate requests and stop if no more data

    setLoading(true);
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}home/offers?offset=0&limit=10`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 401) {
        navigate("/login"); // Redirect to login on unauthorized access
      } else if (
        response.data &&
        response.data.data &&
        response.data.data.offers
      ) {
        const newOffers = response.data.data.offers;
        setOfferData(newOffers);
      } else {
        setErrorMessage("No more offers available.");
        setHasMore(false); // No more offers to load
      }
    } catch (error) {
      setErrorMessage("Error fetching offers. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch offers on component mount
  useEffect(() => {
    fetchOffers();
  }, []);

  return (
    <div className="offers p-60">
      <div className="container">
        <h1 className="f-40 text-center mb-3">Offers</h1>
        <p className="f-20 grey text-center">
          Special offers handpicked for you
        </p>
        {errorMessage ? (
          <p className="text-center text-danger">{errorMessage}</p>
        ) : (
          <div className="row offer-item ">
            {offerData.map((item) => (
              <div key={item.id} className="gift-card col-md-3  ">
                <Link to={`/offerdetail/${item.id}`}  className="offer-card  shadow rounded-3 d-block bg-white">
                  {/* <div> */}
                    <img src={item.thumbnail} className="product-image" />
                    <h3 className="f-20 mb-1 tuncat-offer">{item.name}</h3>
                    <strong className="fs-6 ">
                      Discount:{" "}
                      {item.discount_amount && item.discount_max
                        ? `${item.discount_max}% Upto ₹${item.discount_amount}`
                        : item.discount_type === "BY_FIXED"
                        ? `₹${item.discount_amount}`
                        : `${item.discount_max}%`}
                    </strong>

                    <p className="f-12 badge-success text-success fs-6 mt-3">
                      Valid: {item.start_date} to {item.end_date}
                    </p>
                  {/* </div> */}
                </Link>
              </div>
            ))}
          </div>
        )}
        {loading && (
          <>
            <Preloader />
          </>
        )}
        <Strip />
      </div>
    </div>
  );
};

export default Offers;
