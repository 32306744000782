import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Review.css'; // Ensure this file contains the necessary styles
import Title from '../Title/Title';
import profileimage from "../Assets/Icon/profile-image.png";

const Review = () => {
  const [reviewsData, setReviewsData] = useState([]);
  const [error, setError] = useState(false);

  // Slick Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Function to fetch reviews from the API
  const fetchReviews = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}menu/reviews?offset=0&limit=10`);
      const data = await response.json();

      if (data.status === 200) {
        setReviewsData(data.data.reviews);
      } else {
        setError(true);
      }
    } catch (err) {
      // console.error('Error fetching reviews:', err);
      setError(true);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  return (
    <div className='slider-container p-60 '>
      <Title title="What Our Clients Say About Us" />
      <section className="review-body">

    
      <Slider {...settings}>
        {error ? (
          <div className="no-data-text">Failed to load reviews.</div>
        ) : reviewsData.length === 0 ? (
          <div className="no-data-text">No reviews available.</div>
        ) : (
          reviewsData.map((review, index) => (
            <div key={index}>
              <div className="review-card bg-white shadow-sm">
                <div className="review-header">
                <img
                src={profileimage}
                className="profile-image mb-1"
                alt="profile"
                height="50px"
                width="50px"
              />
                  <h3 className="reviewer-name f-20">{review.review}</h3>
                  <p className="review-role f-14 grey-85">{review.role}</p>
                  <div className="rating">
                    {"★".repeat(review.rating) + "☆".repeat(5 - review.rating)}
                  </div>
                </div>
      
                <p className="review-text display-webkit"      dangerouslySetInnerHTML={{ __html: review.description }}></p>
              </div>
            </div>
          ))
        )}
      </Slider>
      </section>
    </div>
  );
};

export default Review;
