
import react from 'react'
import UnderMaintenance from '../Components/Maintenance/UnderMaintenance'

function Maintenance (){

    return(
        <>
        <UnderMaintenance/>
        </>
    )
}

export default Maintenance