import React from 'react';
import Banner from '../Components/GifziPlus/Banner';
import Content from '../Components/GifziPlus/Content';
import MyCard from '../Components/GifziPlus/MyCard';

function GiftPlus() {
  const token = localStorage.getItem('authToken'); // Retrieve auth token

  return (
    <div className='position-relative'>
      <Banner />
      {token && <MyCard  limit={4} showViewAll={true} />} {/* Show Mycard only if the user is logged in */}
      <Content />
    </div>
  );
}

export default GiftPlus;


// import React from 'react';
// import Banner from '../Components/GifziPlus/Banner';
// import Content from '../Components/GifziPlus/Content';
// import MyCard from '../Components/GifziPlus/MyCard';
// import SEO from '../SEO';


// function GiftPlus() {
//   const token = localStorage.getItem('authToken'); // Retrieve auth token

//   return (
//     <div className='position-relative'>
//       {/* SEO Component for dynamic metadata */}
//       <SEO
//         title="GiftPlus - Exclusive Gifts for You"
//         description="Discover exclusive gifts and offers only on GiftPlus. Unlock the best deals and surprises."
//         keywords="GiftPlus, gifts, exclusive offers, deals"
//         author="Your Name"
//       />
      
//       <Banner />
//       {token && <MyCard limit={4} showViewAll={true} />} {/* Show MyCard only if the user is logged in */}
//       <Content />
//     </div>
//   );
// }

// export default GiftPlus;
