import React, { useState, useEffect, useCallback } from "react";
import Input from "../Input/Input";
import Select from "../Input/Select";
import "./Myprofile.css";
import Commonbtn from "../Commonbtn/Commonbtn";
import profileimage from "../Assets/Icon/profile-image.png";
import camera from "../Assets/Icon/camera.svg";

function Myprofile() {
  
  const citylist = ["Select a City", "Udaipur", "Jaipur", "Jodhpur"];
  const statelist = ["Select a State", "Rajasthan", "Gujarat"];
  const [states, setStates] = useState([]);
  const [id, setUserId] = useState("");
  const [selectedCity, setSelectedCity] = useState(citylist[0]);
  const [postcode, setPostcode] = useState("");
  const [regions, setRegions] = useState([]);
  const [selectedState, setSelectedState] = useState(statelist[0]);
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setContactNumber] = useState("");
  const [address, setAddress] = useState("");
  const [account_no, setaccount_no] = useState("");
  const [ifsc, setifsc] = useState("");
  const [aadhar_no, setAadhaarNumber] = useState("");
  const [pan_no, setPanNumber] = useState("");
  const [old_password, setOldPassword] = useState("");
  const [password, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [panError, setPanError] = useState("");
  const [aadharError, setAadharError] = useState("");
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [cities, setCities] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fieldErrors, setFieldErrors] = useState("");
  const [passwordErrors, setpasswordErrors] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("authToken");

      const id = localStorage.getItem("userId");
      setUserId(id);

      if (id) {
        // Set userId to state if needed
        // console.log("User ID:", id);
        // console.log("toekn", token);
      }

      if (!token) {
        setError("Authentication token is missing.");
        return;
      }

      try {
        // Fetch user data
        const userResponse = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}user/profile`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        if (userResponse.status === 401) {
          // Unauthorized, clear token and redirect to login
         
          window.location.href = "login"; // Redirect to login page
          return;
        }
        if (userResponse.ok) {
          const result = await userResponse.json();
          const userData = result.data?.userDetail;
          const accountDetail = result.data?.accountDetail;
          const kycDetail = result.data?.kycDetail;
          const addressDetail = result.data?.addressDetail;

          // Populate state with user data
          setfirst_name(userData.first_name || "");
          setlast_name(userData.last_name || "");
          setEmail(userData.email || "");
          setContactNumber(userData.mobile || "");
          setProfileImage(userData.avatar || "");
          //setAddress(userData.address || "");
          setaccount_no(accountDetail.account_no || "");
          setifsc(accountDetail.ifsc || "");
          setAadhaarNumber(kycDetail.aadhar_no || "");
          setPanNumber(kycDetail.pan_no || "");

          setSelectedState(userData.state || "");
          setPostcode(addressDetail.postcode || "");
          setAddress(addressDetail.line1 || "");
          setSelectedRegion(addressDetail.region_id || "");

          if (addressDetail.region_id) {
            setTimeout(async () => {
              await fetchCities(
                addressDetail.region_id,
                token,
                addressDetail.city_id
              );
            }, 2000);
          }
        }

        // Fetch region data
        const regionResponse = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}address/region/101`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          }
        );

        if (regionResponse.ok) {
          const regionResult = await regionResponse.json();
          setRegions(regionResult.data?.regions || []);
        } else {
          setError("Failed to load regions.");
        }
      } catch (err) {
        setError("An error occurred while fetching data.");
      }
    };

    fetchData();
    //fetchState();
  }, []);

  

  const fetchCities = async (regionId, token, city_id) => {
    try {
      const cityResponse = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}address/city/${regionId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (cityResponse.ok) {
        const cityResult = await cityResponse.json();
        setCities(cityResult.data?.cities || []);
        setSelectedCity(city_id);
      } else {
        // console.error("Failed to load cities.");
      }
    } catch (error) {
      // console.error("An error occurred while fetching cities:", error);
    }
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };
  const handleRegionChange = async (e) => {
    const selectedRegionId = e.target.value;
    setSelectedRegion(selectedRegionId);

    if (selectedRegionId) {
      try {
        // Call the API to fetch cities for the selected region
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}address/city/${selectedRegionId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${localStorage.getItem("authToken")}`,
            },
          }
        );

        if (response.ok) {
          const result = await response.json();
          setCities(result.data?.cities || []);
        } else {
          // console.error("Failed to load cities.");
        }
      } catch (error) {
        // console.error("An error occurred while fetching cities:", error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    const token = localStorage.getItem("authToken");

    const data = {
      profileImage,
      first_name,
      last_name,
      email,
      mobile,
      address,
      city: selectedCity,
      state: selectedState,
      account_no,
      ifsc,
      id,
      aadhar_no,
      pan_no,
      old_password,
      password,
    };

    // console.log("asdf");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}user/editProfile`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();
      // console.log("aaaaaaaaaaaaaaaaa", result);

      if (!response.ok) {
        const result = await response.json();
        setError(result.message || "Profile update failed. Please try again.");
      } else {
        alert("Profile updated successfully");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImage(imageUrl);
      setSelectedFile(file);
    } else {
      setError("Failed to upload image.");
    }
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };

  const handlePersonalDetailSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous errors
    setLoading(true); // Start loading state
    const token = localStorage.getItem("authToken");
    const formData = new FormData();
  
    formData.append("salutation", "Mr");
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("id", id);
    if (selectedFile) {
      formData.append("avatar", selectedFile);
    }
  
    // console.log("Form Data:", {
    //   salutation: "Mr",
    //   first_name,
    //   last_name,
    //   email,
    //   mobile,
    //   id,
    //   avatar: selectedFile ? selectedFile.name : "No file selected",
    // });
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}user/editPersonalDetail`,
        {
          method: "POST",
          headers: {
            Authorization: token,
          },
          body: formData,
        }
      );
  
      // console.log("Full response:", response);
  
      if (response.ok) {
        const result = await response.json();
        alert("Personal details updated successfully");
      } else {
        const errorResponse = await response.json();
        // console.error("Error response:", errorResponse.errors.errors);
  
        // Handling validation errors specifically
        let errorMessages = errorResponse.errors.errors.map((error) => error.msg).join(", ");
        setError(errorMessages || "Failed to update personal details. Please try again.");
      }
    } catch (error) {
      // console.error("Fetch error:", error);
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false); // End loading state
    }
  };
  
 
  const handleAccountDetailSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setError(""); // Clear any previous errors
      setLoading(true); // Set loading state
  
      const token = localStorage.getItem("authToken");
      const accountData = {
        account_no,
        ifsc,
        id,
      };
  
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}user/editAccountDetail`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify(accountData),
          }
        );
  
        const result = await response.json();
  
        if (response.ok) {
          alert("Account details updated successfully");
          setFieldErrors("")
        } else {
          // If validation errors are present, extract and show them
          if (result.errors && result.errors.errors) {
            const validationErrors = result.errors.errors.map((err) => err.msg).join(", ");
            setFieldErrors(validationErrors || "Failed to update account details. Please try again.");
          } else {
            // Fallback error message if no specific validation errors are provided
            setFieldErrors(result.message || "Failed to update account details. Please try again.");
          }
        }
      } catch (error) {
        setError("An error occurred. Please try again.");
      }
  
      setLoading(false); // Set loading state to false
    },
    [account_no, ifsc, id] // Dependencies for useCallback hook
  );
  

  const handleKycDetailSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setPanError("");
      setAadharError("");

      const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
      if (!panRegex.test(pan_no)) {
        setPanError(
          "Invalid PAN number. Please follow the correct format (e.g., ABCDE1234F)."
        );
        setLoading(false);
        return;
      }

      const aadharRegex = /^[2-9][0-9]{11}$/;
      if (!aadharRegex.test(aadhar_no)) {
        setAadharError(
          "Invalid Aadhaar number. It should be a 12-digit number."
        );
        setLoading(false);
      }

      const kycData = {
        aadhar_no,
        pan_no,
        id,
      };

      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}user/editKycDetail`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify(kycData),
          }
        );

        const result = await response.json();
        if (response.ok) {
          alert("KYC details updated successfully");
          setError('')
          setAadharError("")
          setPanError('')
        } else {
          setError(
            result.message || "Failed to update KYC details. Please try again."
          );
        }
      } catch (error) {
        setError("An error occurred. Please try again.");
      }

      setLoading(false);
    },
    [aadhar_no, pan_no, id]
  );

  const isPasswordValid = (password) => {
    return /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,16}$/.test(password);
  };
  const handleChangePasswordSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      // console.log("Form submission started");
      setpasswordErrors("");
      setLoading(true);
  
      if (!old_password.trim() || !password.trim() || !confirmPassword.trim()) {
        setpasswordErrors("All fields are required.");
        setLoading(false);
        // console.log("Validation failed: Missing fields");
        return;
      }
      
      const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;



      if (password.length > 0 && !passwordPattern.test(password.trim())) {
        setpasswordErrors("Password must be 8-16 characters long and contain both letters and numbers.");
        setLoading(false);
        // console.log("Validation failed: Password format");
        return;
      }
  
      if (password !== confirmPassword) {
        setpasswordErrors("New passwords do not match");
        setLoading(false);
        // console.log("Validation failed: Passwords do not match");
        return;
      }
  
      const token = localStorage.getItem("authToken");
      if (!token) {
        setpasswordErrors("No authentication token found. Please log in.");
        setLoading(false);
        // console.log("No token found");
        return;
      }
  
      const changePasswordData = {
        old_password,
        password,
        id,
      };
  
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}user/password/change`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify(changePasswordData),
          }
        );
  
        const result = await response.json();
        // console.log("API Response:", result);
  
        if (response.ok) {
          alert("Password changed successfully");
        } else {
          setpasswordErrors(result.message || "Failed to change password. Please try again.");
          // console.log("API error:", result.message);
        }
      } catch (error) {
        // console.error("Request Error:", error.message);
        setpasswordErrors("An error occurred. Please try again.");
      }
  
      setLoading(false);
      // console.log("Form submission ended");
    },
    [old_password, password, confirmPassword, id]
  );
  
  const handleAddressSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setError("");
      setLoading(true);

      const token = localStorage.getItem("authToken");
      if (!token) {
        setError("No authentication token found. Please log in.");
        setLoading(false);
        return;
      }

      const addressData = {
        country_id: 101, // Assuming country is static or predefined
        region_id: selectedRegion,
        city_id: selectedCity,
        postcode: postcode,
        line1: address,
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}user/editAddressDetail`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: ` ${token}`,
            },
            body: JSON.stringify(addressData),
          }
        );

        // Log status
        // console.log("Response Status:", response.status);

        // Log headers
        response.headers.forEach((value, name) => {
          // console.log(`${name}: ${value}`);
        });

        const result = await response.json();
        // console.log("API Response:", result);

        if (response.ok) {
          alert("Address updated successfully");
        } else {
          setError(
            result.message || "Failed to update address. Please try again."
          );
        }
      } catch (error) {
        // console.error("Request Error:", error);
        setError("An error occurred. Please try again.");
      }

      setLoading(false);
    },
    [selectedRegion, selectedCity, postcode, address]
  );

  useEffect(() => {
    // Function to calculate completion percentage
    const calculateCompletionPercentage = () => {
      const fields = [
        first_name,
        last_name,
        email,
        mobile,
        address,
        selectedCity,
        selectedState,
        account_no,
        ifsc,
        id,
        aadhar_no,
        pan_no,
      ];
      const filledFields = fields.filter(
        (field) =>
          field && field !== "Select a City" && field !== "Select a State"
      );
      const percentage = Math.round(
        (filledFields.length / fields.length) * 100
      );
      setCompletionPercentage(percentage);
    };

    calculateCompletionPercentage();
  }, [
    first_name,
    last_name,
    email,
    mobile,
    address,
    selectedCity,
    selectedState,
    account_no,
    ifsc,
    id,
    aadhar_no,
    pan_no,
  ]);

  // update
  return (
    <div className="">
      <div className="container">
        <h2 className="f-40 f-weight-600 mb-4 mt-3">Profile</h2>
      
        <div className="bg-fb my-profile">
          <div className="row profileimage">
            <div className="col-md-12 d-flex-center">
              <img
                src={profileImage || "/default-profile.png"}
                className="profile-image"
                alt="profile"
                height="130px"
                width="130px"
              />
              <span className="progress-bar">{completionPercentage}%</span>{" "}
              <i
                className="profile-cameraicon shadow-sm"
                onClick={triggerFileInput}
              >
                <img
                  src={camera}
                  className="profile-camera"
                  alt="camera"
                  height="40"
                  width="40px"
                />
              </i>
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageUpload}
              />
            </div>
          </div>
          <div className="personal-detail">
            <h2 className="f-28 text-main mb-3 f-weight-600">
              Personal Detail
            </h2>
            <form onSubmit={handlePersonalDetailSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <Input
                    fortext="id"
                    name="id"
                    typetext="hidden"
                    value={id}
                    labeltext=""
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    placeholdertext="Enter your First name"
                    name="first_name"
                    labeltext="Name"
                    fortext="name"
                    typetext="text"
                    value={first_name}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[A-Za-z\s]*$/.test(value)) {
                        // Allows only letters and spaces
                        setfirst_name(value);
                      }
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    placeholdertext="Enter your Last name"
                    name="last_name"
                    labeltext="Last name"
                    fortext="name"
                    typetext="text"
                    value={last_name}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[A-Za-z\s]*$/.test(value)) {
                        // Allows only letters and spaces
                        setlast_name(value);
                      }
                    }}
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    placeholdertext="Email"
                    labeltext="Email"
                    name="email"
                    fortext="email"
                    typetext="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    placeholdertext="9876543210"
                    name="mobile"
                    maxLength={10}
                    labeltext="Contact Number"
                    fortext="number"
                    typetext="tel"
                    value={mobile}
                    onChange={(e) => setContactNumber(e.target.value)}
                    disabled
                  />
                </div>
                {/* <div className='col-md-12'>
                  <Input placeholdertext="country" name="country_id" labeltext="Country" fortext="text" typetext="text" value={country_id} onChange={(e) => setContactNumber(e.target.value)} />
                </div> */}
                {/*                
                <div className='col-md-6'>
                  <Select fortext="country" name="country_id" labeltext="Country" options={country_id} selectedValue={selectedCountry} onChange={handleCountryChange} />
                </div> */}
  {error && <div className="error-message">{error}</div>}{" "}
                <div className="col-md-12 btn-flex-center d-flex gap-2">
                  <Commonbtn
                     Commonbtntext={"Save"}
                    disabled={loading}
                  />
                  <button type="button" className="Commonredbtn">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
            
          </div>
          <div className="personal-detail">
            <h2 className="f-28 text-main mb-3 f-weight-600">Address Detail</h2>
            <form onSubmit={handleAddressSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <Input
                    fortext="id"
                    name="id"
                    typetext="hidden"
                    value={id}
                    labeltext=""
                  />
                </div>

                <div className="col-md-6">
                  <label className="label" htmlFor="State">
                    State
                  </label>
                  <select
                    name="region_id"
                    className="form-control shadow-sm"
                    value={selectedRegion} // Bind the selected region to this value
                    onChange={handleRegionChange} // Call the handler on change
                  >
                    <option value="">Select a Region</option>
                    {regions.map((region) => (
                      <option key={region.region_id} value={region.region_id}>
                        {region.region_title}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-6">
                  <label className="label" htmlFor="city">
                    City
                  </label>
                  <select
                    name="city_id"
                    className="form-control shadow-sm"
                    value={selectedCity}
                    onChange={handleCityChange}
                    disabled={!selectedRegion} // Disable city select until a region is selected
                  >
                    <option value="">Select a City</option>
                    {cities.map((city) => (
                      <option key={city.city_id} value={city.city_id}>
                        {city.city_title}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-12">
                  <Input
                    placeholdertext="Address"
                    name="address"
                    labeltext="Address"
                    fortext="address"
                    typetext="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    placeholdertext="Post Code"
                    name="postcode"
                    labeltext="Postcode"
                    fortext="postcode"
                    typetext="text"
                    value={postcode} // Corrected to use postcode state
                    onChange={(e) => setPostcode(e.target.value)} // Update postcode state
                  />
                </div>
                <div className="col-md-12 btn-flex-center d-flex gap-2">
                  <Commonbtn
                     Commonbtntext={"Save"}
                    disabled={loading}
                  />
                  <button type="button" className="Commonredbtn">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="account-detail">
            <h2 className="f-28 text-main mb-3 f-weight-600">Account Detail</h2>
            <form onSubmit={handleAccountDetailSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <Input
                    fortext="id"
                    name="id"
                    typetext="hidden"
                    value={id}
                    labeltext=""
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    placeholdertext="Bank Account Number"
                    name="account_no"
                    labeltext="Bank Account Number"
                    fortext="account_no"
                    typetext="number"
                    value={account_no}
                    onChange={(e) => setaccount_no(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    placeholdertext="IFSC code"
                    name="ifsc"
                    labeltext="IFSC code"
                    fortext="ifsc"
                    typetext="text"
                    value={ifsc}
                    onChange={(e) => setifsc(e.target.value)}
                  />
                </div>
                <div className="col-md-12 btn-flex-center d-flex gap-2">
                  <Commonbtn
                     Commonbtntext={"Save"}
                    disabled={loading}
                  />
                  <button type="button" className="Commonredbtn">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
            { <div className="error-message">{fieldErrors}</div>}{" "}
          </div>
          {" "}
          <div className="kyc-detail">
            <h2 className="f-28 text-main mb-3 f-weight-600">KYC Detail</h2>
            <form onSubmit={handleKycDetailSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <Input
                    fortext="id"
                    name="id"
                    typetext="hidden"
                    value={id}
                    labeltext=""
                  />
                </div>

                {/* Aadhaar Number */}
                <div className="col-md-12">
                  <Input
                    placeholdertext="Aadhaar Number"
                    name="aadhar_no"
                    labeltext="Aadhaar Number"
                    fortext="aadhaar"
                    typetext="number"
                    value={aadhar_no}
                    maxLength={12}
                    onChange={(e) => setAadhaarNumber(e.target.value)}
                  />
                  {aadharError && (
                    <p className="error-message">{aadharError}</p>
                  )}
                </div>

                {/* PAN Number */}
                <div className="col-md-12 pancard">
                  <Input
                    placeholdertext="PAN Card Number"
                    name="pan_no"
                    labeltext="PAN Card Number"
                    fortext="pan"
                    typetext="text"
                    value={pan_no}
                    maxLength={10} // Corrected to 10 characters
                    onChange={(e) => setPanNumber(e.target.value.toUpperCase())}
                  />
                  {panError && <p className="error-message">{panError}</p>}
                </div>

                <div className="col-md-12 btn-flex-center d-flex gap-2">
                  <Commonbtn
                     Commonbtntext={"Save"}
                    disabled={loading}
                  />
                  <button type="button" className="Commonredbtn">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="change-password-detail">
            <h2 className="f-28 text-main mb-3 f-weight-600">
              Change Password
            </h2>
            <form onSubmit={handleChangePasswordSubmit}>
      <div className="row">
        <div className="col-md-12">
          <Input
            placeholdertext="Old Password"
            name="old_password"
            labeltext="Old Password"
            fortext="oldPassword"
            typetext="password"
            value={old_password}
          
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <Input
            placeholdertext="New Password"
            name="password"
            labeltext="New Password"
            fortext="newPassword"
            typetext="password"
            value={password}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <Input
            placeholdertext="Confirm Password"
            name="confirmPassword"
            labeltext="Confirm Password"
            fortext="confirmPassword"
            typetext="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        {passwordErrors && <p style={{ color: "red" }}>{passwordErrors}</p>} {/* Error Message Display */}
        <div className="col-md-12 btn-flex-center d-flex gap-2">
          <Commonbtn
             Commonbtntext={"Save"}
            disabled={loading}
          />
          <button type="button" className="Commonredbtn">
            Cancel
          </button>
        </div>
      </div>
    </form>
          </div>
          {/* </form> */}
        </div>
      </div>
    </div>
  );
}

export default Myprofile;
