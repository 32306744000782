import React, { useEffect, useState } from 'react';
import Title from '../../Title/Title';
import './Team.css';

function Team() {
  // State to store team data
  const [teamData, setTeamData] = useState([]);

  // Fetch team data from API on component mount
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_API_URL}menu/team?offset=0&limit=10`) // Replace {{baseUrl}} with the actual base URL
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setTeamData(data.data.members); // Update state with fetched team data
        }
      })
      .catch((error) => {
        // console.error('Error fetching team data:', error);
      });
  }, []);

  return (
    <div className="team-list bg-fb p-60">
      <div className="container">
        <Title title="Meet the Team" />
        <div className="row justify-content-center">
          {teamData.map((member, index) => (
            <div key={index} className="col-sm-12 col-md-6 col-lg-6 d-flex ">
              <div className="card team-card flex-row gap-3">
                <img src={member.image} alt={member.name}  height="100%" width="100%"/>
                <div className="team-info">
                  <p className="f-14 text-grey  f-weight-500">{member.designation}</p>
                  <h2 className="f-20 mb-1 f-weight-600 mb-2">{member.name}</h2>
                  <p
                    className="f-14 greyf6 team-detail"
                    dangerouslySetInnerHTML={{ __html: member.description }}
                  ></p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Team;
