import React from 'react';
import Commonbtn from '../Commonbtn/Commonbtn';
import './Product.css';
import amazone from '../Assets/amazone.png';  // Image imports (not needed since we are using product images from API)
import ribbon from '../Assets/Ribbon.svg';  // Image imports (not needed since we are using product images from API)
import { Link } from 'react-router-dom';

function Product() {
  const [products, setProducts] = React.useState([]);

  const fetchProductData = async () => {
    const baseUrl = `${process.env.REACT_APP_BASE_API_URL}home/homeData`;

    try {
      const fetchData = await fetch(baseUrl, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        }
      });

      const jsonData = await fetchData.json();

      if (jsonData && jsonData.status === 200) {
        let productJson = jsonData.data?.products; // Use let instead of const

        // Replace specific image URLs
        productJson = productJson.map((item) => {
          // console.log('item', item);
          if (item.thumbnail == "https://gbdev.s3.amazonaws.com/uat/product/CLAIMCODE/d/thumbnail/327_microsite.jpg") {
            item.thumbnail = "https://www.crafin.in/wp-content/uploads/2024/08/Sell_amazon_shopping_voucher-300x169.png";
          } else if (item.thumbnail == "https://gbdev.s3.amazonaws.com/uat/product/CNPIN/d/thumbnail/324_microsite.png") {
            item.thumbnail = "https://www.crafin.in/wp-content/uploads/2019/08/Flipkart-Gift-Cards-300x169.jpg";
          } else if (item.thumbnail == "https://gbdev.s3.amazonaws.com/uat/product/EGCGBAMZ001/d/thumbnail/135_microsite.jpg") {
            item.thumbnail = "https://www.crafin.in/wp-content/uploads/2019/03/amazon.png";
          } else if (item.thumbnail == "https://gbdev.s3.amazonaws.com/uat/product/EGCGBBMS001/d/thumbnail/217_microsite.png") {
            item.thumbnail = "https://www.crafin.in/wp-content/uploads/2023/06/zomato_vouchers-300x169.jpg";
          } else if (item.thumbnail == "https://gbdev.s3.amazonaws.com/uat/product/EGCGBFK001/d/thumbnail/66_microsite.jpg") {
            item.thumbnail = "https://www.crafin.in/wp-content/uploads/2023/06/swiggy-money-gift-cards-300x169.jpeg";
          } else if (item.thumbnail == "https://gbdev.s3.amazonaws.com/uat/product/EGVGBSHSS001/d/thumbnail/107_microsite.jpg") {
            item.thumbnail = "https://www.crafin.in/wp-content/uploads/2024/08/Amazon-Fresh-Vouchers-300x169.png";
          }

          return item;
        });
        
        // console.log('productJson', productJson); // To verify the product data
        setProducts(productJson);
      }
    } catch (err) {
      // console.log(err);
    }
};


  React.useEffect(() => {
    fetchProductData();
  }, []);

  return (
    <div className='product-list p-60 text-center bg-fb home-p'>
      <div className='container'>
        <h3 className='title'>Gift Cards and Gift Vouchers</h3>
        <div className='row'>
          {products?.map((item, index) => (
            <div key={index} className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6 gift-card' data-aos="fade-right" data-aos-delay="300">
              {/* Pass the sku of the product in the Link */}
              <Link to={`/productdetail/${item.sku}`} className='card d-block border-0'>
                {/* <h3 className='product-name'>{item.categoryname}</h3> */}
                {item.flat_discount ? (
      <i className='flat-off position-absolute'>
      <div className='ribbon position-relative'>
        <img src={ribbon} height="100%" width="100%"/>
           <p className="m-0 p-0 flat-text">   {item.flat_discount}% Flat off</p>
      </div>
    </i>
  ) : null}
              
            <i className='product-image'>
                <img
                  src={item.thumbnail}  // Use thumbnail from API
                  alt={item.name}
                  className='rounded'
                   height="100%" width="100%"
                />
                
                </i>
              
                       
                <h3
                className='product-name'
                 
                >
                  {item?.name}
                </h3>
             
              </Link>
            </div>
          ))}

          <div className='col-md-12'>
            <Link to='/gift-cards'>
              <Commonbtn Commonbtntext="SEE ALL" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;
