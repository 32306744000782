import React, { useEffect, useState } from "react";
import Input from "../Input/Input";
import './MyCart.css';
import { useNavigate } from "react-router-dom";
import axios from "axios";

function SomeoneElse({ onGiftChange, onGiftDetailChange,onchecksms }) {
  const [isChecked, setIsChecked] = useState(false); // Checkbox for "Order for Someone Else"
  const [detail, setDetail] = useState({
    firstname: "",
    email: "",
    telephone: "",
    gift_message: "",
   
  });
  const [notificationPreferences, setNotificationPreferences] = useState({
    email: true,
    whatsapp: false,
    sms: false,
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev); // Toggle visibility
    onGiftChange(!isChecked);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const updatedDetail = { ...detail, [name]: value };

    setDetail(updatedDetail);
    onGiftDetailChange(updatedDetail);

    // Update local storage
    localStorage.setItem("giftDetails", JSON.stringify(updatedDetail));
  };

  const handleNotificationChange = (e) => {
    const { name, checked } = e.target;
  
    // Ensure only one checkbox (WhatsApp or SMS) can be checked at a time
    setNotificationPreferences((prev) => ({
      ...prev,
      whatsapp: name === "whatsapp" ? checked : false,
      sms: name === "sms" ? checked : false,
    }));
  
    // Call the onchecksms prop with the updated values
    if (onchecksms) {
      onchecksms({
        is_sms: name === "sms" && checked ? "1" : "0",
        is_wa: name === "whatsapp" && checked ? "1" : "0",
      });
    }
  };
  
  

  const fetchOffers = async () => {
    setLoading(true);
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}user/profile`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 401) {
        navigate("/login");
      } else if (response.data.data) {
        const newOffers = response.data.data.userDetail;
        setDetail((prevDetail) => ({
          ...prevDetail,
          ...newOffers,
        }));
      } else {
        setErrorMessage("No data available.");
      }
    } catch (error) {
      setErrorMessage("Error fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, []);

  return (
    <>
      <div className="mb-4">
        <div className="container">
          <div className="row">
            <div className="p-0">
              <div className="card p-1">
                <div className="d-flex justify-content-between btn-g">
                  <div className="d-flex flex-row align-items-center ms-2">
                    {/* <div className="icon">
                      <img src="https://media.istockphoto.com/id/1125279178/vector/mail-line-icon.jpg?s=612x612&w=0&k=20&c=NASq4hMg0b6UP9V0ru4kxL2-J114O3TaakI467Pzjzw=" height="35px" width="35px" alt="icon" />
                    </div> */}
                    <div className="d-flex gap-3 flex-wrap ">
                      <div className="ms-2 c-details text-break d-flex align-items-center gap-1 ">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" className="svg-inline--fa fa-user f-15" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z"></path></svg>
                        <h6 className="mb-0">{detail.firstname || detail.first_name}</h6>
                      </div>
                      <div className="ms-2 c-details text-break d-flex align-items-center gap-1">
                      <img src="https://media.istockphoto.com/id/1125279178/vector/mail-line-icon.jpg?s=612x612&w=0&k=20&c=NASq4hMg0b6UP9V0ru4kxL2-J114O3TaakI467Pzjzw=" height="35px" width="35px" alt="icon" />
                        <h6 className="mb-0">{detail.email}</h6>
                      </div>
                      <div className="ms-2 c-details text-break d-flex align-items-center gap-1">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone" className="svg-inline--fa fa-phone " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"></path></svg>
                        <h6 className="mb-0">{detail.telephone || detail.mobile}</h6>
                      </div>
                    </div>

                    
                  </div>
                  
                </div>
                <div className="icon  gap-2 text-black w-100    ">
                      <img src="https://static.vecteezy.com/system/resources/thumbnails/002/206/240/small/fast-delivery-icon-free-vector.jpg"  className="vert-midle" alt="icon" height="35px" width="35px"/>
                      <strong className="f-16 font-w-600">
               Get Voucher Detail On
               </strong>
                    </div>
                <div className="d-flex gap-4 ms-2">
           
              
               
            <div className="d-flex gap-2 text-black align-items-center">
              <input
                type="checkbox"
                name="email"
                disabled
                checked={notificationPreferences.email}
                onChange={handleNotificationChange}
              />
              <label  className="text-black pt-1 f-14 ">Email</label>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <input
                type="checkbox"
                name="whatsapp"
                checked={notificationPreferences.whatsapp}
                onChange={handleNotificationChange}
              />
              <label  className="text-black pt-1 f-14">WhatsApp</label>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <input
                type="checkbox"
                name="sms"
                checked={notificationPreferences.sms}
                onChange={handleNotificationChange}
              />
              <label  className="text-black pt-1 f-14">SMS</label>
            </div>
          </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="someelse d-flex-center">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <p className="f-18 f-weight-600 text-o">ORDER FOR SOMEONE ELSE</p>
        </div>
      </div>

      <div className={`bg-fb p-0 someone-detail ${isChecked ? "show-detail" : "hide-detail"}`}>
        <Input
          placeholdertext="Enter recipient's First Name"
          labeltext="First Name"
          fortext="firstname"
          typetext="text"
          name="firstname"
          value={detail.firstname}
          onChange={handleInputChange}
        />
        <Input
          placeholdertext="Enter recipient's phone number"
          labeltext="Phone Number"
          fortext="telephone"
          typetext="number"
          name="telephone"
          value={detail.telephone}
          onChange={handleInputChange}
          maxLength={10}
        />
        <Input
          placeholdertext="Enter recipient's Email"
          labeltext="Email"
          fortext="email"
          typetext="email"
          name="email"
          value={detail.email}
          onChange={handleInputChange}
        />
        <Input
          placeholdertext="Enter a gift message"
          labeltext="Message"
          fortext="gift_message"
          typetext="text"
          name="gift_message"
          value={detail.gift_message}
          onChange={handleInputChange}
          maxLength={50}
        />
     
      </div>
    </>
  );
}

export default SomeoneElse;
