import React, { useEffect, useState } from 'react';
import axios from 'axios';
import westside from '../Assets/westside.png'; 
import gifziplus from '../Assets/gifziplus.jpeg'; 
import Commonbtn from '../Commonbtn/Commonbtn';
import SearchBar from '../Searchbar/Searchbar';
import './Myorder.css';
import { Link, useNavigate } from 'react-router-dom';
import Preloader from '../Preloader/Preloader';
import EmptyCart from '../MyCart/EmptyCart';

function Myorder() {
  const [orderHistoryData, setOrderHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [limit, setLimit] = useState(20); // Items per page
  const [offset, setOffset] = useState(0); // Pagination offset
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [totalCount, setTotalCount] = useState(0); // Total orders
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();

  const Empty = {
    title: 'No Order available!',
    subtitle: 'It seems like you haven’t ordered anything yet',
    subtext: 'Start shopping to fill it up with amazing deals',
    link: '/gift-cards',
    btntext: 'Shop Now',
  }

  // Fetch order history with optional search query
  const fetchOrderHistory = async (query = '') => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}order/${query ? 'search' : 'all'}?offset=${offset}&limit=${limit}${query ? `&search=${query}` : ''}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token, // Ensure token is passed correctly
          },
        }
      );

      if (response.data.status === 401 && response.data.message === "Unauthorized") {
        navigate("/login");
        return;
      }
      
      if (response.status === 200) {
        const orders = response.data.data?.orders;
        setTotalCount(response.data.data?.totalOrders || 0); // Set total count from response
        if (orders && orders.length > 0) {
          setOrderHistoryData(orders);
          setError(null);
        } else {
          setOrderHistoryData([]);
          setError(null);
        }
      } else {
        setError('Failed to fetch orders.');
      }
      setLoading(false);
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          setError(err.response.data.message || 'Failed to fetch orders.');
        }
      } else {
        setError('An unexpected error occurred.');
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchOrderHistory(); // Fetch orders initially
    } else {
      setError('No authorization token found.');
      setLoading(false);
    }
  }, [token, offset, limit]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    fetchOrderHistory(query);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setOffset((page - 1) * limit); // Update offset based on page number
  };

  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setCurrentPage(1); // Reset to page 1 on limit change
    setOffset(0); // Reset offset
  };

  const totalPages = Math.ceil(totalCount / limit);

  if (loading) {
    return <Preloader />;
  }

  if (error) {
    return (
      <div className='Orderhistorydata p-60 bg-fb'>
        <div className='container Orderhistorydata-list'>
          <h2 className='f-40'>Order History</h2>
          <div className='row btn-list-order align-items-center'>
            <div className='col-md-12'>
              <SearchBar
                searchtext="Search Here By Order Id"
                searchtype="text"
                searchclass="shadow-sm mt-3 mb-3"
                onSearch={handleSearch}
              />
            </div>
          </div>
          <EmptyCart Empty={Empty} />
        </div>
      </div>
    );
  }

  return (
    <div className='Orderhistorydata p-60 bg-fb'>
      <div className='container Orderhistorydata-list'>
        <h2 className='f-40'>Order History</h2>
        <div className='row btn-list-order align-items-center'>
          <div className='col-md-12'>
            <SearchBar
              searchtext="Search Here By Order Id"
              searchtype="text"
              searchclass="shadow-sm mt-3 mb-3"
              onSearch={handleSearch}
            />
          </div>
        </div>

        <div className='Orderhistorydata-list-main'>
          {orderHistoryData.length > 0 ? (
            orderHistoryData.map((item, index) => (
              <Link to={`/Orderdetail/${item.id}`} key={index}>
                <div className='Orderhistorydata-item shadow-sm rounded-3 row mb-3'>
                  <div className='Orderhistorydata-icon col-md-2'>
                    {item.is_gifzi_plus_order === 0 ? (
                      <img src={item.thumbnail || westside} className='text-white f-16' alt="Westside Order" />
                    ) : (
                      <img src={gifziplus} className='text-white' alt="Gifzi Plus Order" />
                    )}
                  </div>
                  <div className='Orderhistorydata-content col-md-6'>
                    <h4 className='f-20 f-weight-600 text-main mb-1'>
                      Order id : <b className='text-main'>{item.code}</b>
                    </h4>
                    <p className='f-18 f-weight-600 mb-2'>
                      Qty : <b>{item.qty}</b>
                    </p>
                    <p className='text-muted f-16'>
                      Order date & time : {item.order_dt}
                    </p>
                  </div>
                  <div className='col-md-4 d-flex-center btn-list'>
                    <button className={`status-status ord btn-lg ${item?.status === 1 ? 'success-status' : item?.status === 2 ? 'failed-status' : 'pending-status'}`}>
                      Status: {item.status === 1 ? 'Success' : item.status === 2 ? 'Failed' : 'Pending'}
                    </button>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <div className='text-muted text-center mt-3'>
              No orders found
              <div className='text-muted mt-3'>Try changing your search criteria or checking back later.</div>
            </div>
          )}
        </div>

       
        <div className="d-flex justify-content-between align-items-center mt-4">
            <div>
              <span>Show </span>
              <select
                value={limit}
                onChange={handleLimitChange}
                className="form-select w-auto d-inline-block  select-width"
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <span> transactions per page</span>
            </div>

            <div className="d-flex justify-content-center mt-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="btn btn-secondary prev-btn" 
                >
               <svg height="25px" width="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path></svg>
             
        
          </button>

          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i + 1}
              onClick={() => handlePageChange(i + 1)}
              className={`btn ${currentPage === i + 1 ? "btn-primary filled-primary" : "btn-secondary outline-page"}`}
            >
              {i + 1}
            </button>
          ))}

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="btn btn-secondary  next-btn"
            >
             <svg height="25px" width="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path></svg>
           
          </button>
        </div>
          </div>
     
       
      </div>
    </div>
  );
}

export default Myorder;
