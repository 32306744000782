import { useEffect, useState } from "react";
import "./BlogDetail.css";
import ArrowRedirect from "../../Commonbtn/ArrowReDirect";
import { useParams } from "react-router-dom";

const BlogDetail = () => {
  const [blog, setBlog] = useState(null); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  // console.log("Blog ID from params: ", id);

  useEffect(() => {
    const fetchBlogDetail = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}menu/blog?id=${id}`
        );
        // console.log(
        //   "Base API URL................: ",
        //   process.env.REACT_APP_BASE_API_URL
        // );

        const result = await response.json();
        // console.log("Blog detail:", result);

        if (response.ok) {
          setBlog(result.data); 
        } else {
          setError(result.message || "Failed to fetch blog details");
        }
      } catch (err) {
        setError("Something went wrong. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogDetail();
  }, [id]);

  if (loading) return <p>Loading blog details...</p>;
  if (error) return <p className="text-danger">{error}</p>;

  return (
    blog && (
      <div className="p-60 ml-14-d userdetail">
        <div className="blog-detail container">
        
        {/* <Title title="{Meet the Team}" /> */}
          <div className="blog-info">
            <img
              src={blog.image} 
              alt="Blog"
              className="blog-image"
              height={200}
              width={300}
            />
            <p className="f-14 grey-85 py-1">Published on: {blog.publised_on}</p>
            <p className="detail-text">{blog.id}</p>
            <p className="f-30 text-dark f-weight-600 py-1">{blog.title}</p>
            <p
              className="f-14 grey-85 py-1"
              dangerouslySetInnerHTML={{ __html: blog.description }}
            ></p>
          </div>
        </div>
      </div>
    )
  );
};

export default BlogDetail;
