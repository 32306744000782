import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import amazonImg from "../Assets/logo.png";
import "./Product.css";
import "../Commonbtn/list.css";
import Input from "../Input/Input";
import Alert from "../Alert/Alert";
import AlertRed from "../Alert/AlertRed";
import { CartContext } from "../../context/CartContext";
import Preloader from "../Preloader/Preloader";
import ribbon from "../Assets/Ribbon.svg"; // Image imports (not needed since we are using product images from API)
import { FaDownload } from "react-icons/fa";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { BsPersonHeart } from "react-icons/bs";
import SEO from "../../SEO";

function ProductDetail() {
  const { sku } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [ShowError, setShowError] = useState(false);
  const [ShowlimitError, setShowlimitError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [price, setPrice] = useState("");
  const [error, setError] = useState("");
  const [selectedDenomination, setSelectedDenomination] = useState(null);
  const [showQtyAlert, setShowQtyAlert] = useState(false); // Alert for exceeding quantity
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const { cartItemCountNav, updateCartCount } = useContext(CartContext);
  const [isOpen, setIsOpen] = useState(null);
  

  const toggleDropdown = (index) => {
    setIsOpen(isOpen === index ? null : index);
  };

  useEffect(() => {
    const fetchProductDetail = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}product/detail?sku=${sku}`
        );
        const data = await response.json();

        if (data && data.status === 200) {
          setProduct(data.data.productDetail[0]);
          if (data.data.productDetail[0].price.denominations.length > 0) {
            setSelectedDenomination(
              data.data.productDetail[0].price.denominations[0]
            );
            setPrice(data.data.productDetail[0].price.denominations[0]);
          }
        } else {
          // console.error("Failed to fetch product details");
        }
      } catch (error) {
        // console.error("Error fetching product details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetail();
  }, [sku]);
  // console.log(product?.price?.max)

  const max = product?.price?.max;
  const min = product?.price?.min;

  const handlePriceChange = (e) => {
    const value = e.target.value;
    setPrice(value);
    if (/^\d*$/.test(value)) {
      const numericValue = parseInt(value, min);

      // Check if the number is within the valid range
      if ((numericValue >= min && numericValue <= max) || value === "") {
        setPrice(value); // Update state only for valid input
      }
    }
    if (value === "" || (value >= min && value <= max)) {
      setError("");
    } else {
      setError(`Price must be between ${min} and ${max}.`);
    }
  };

  const handleDenominationSelect = (denomination) => {
    setSelectedDenomination(denomination);
    setPrice(denomination);
    setError("");
  };

  const addToCart = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      setShowLoginAlert(true);
      setTimeout(() => {
        setShowLoginAlert(false);
        navigate("/login");
      }, 2000);
      return;
    }

    if (error || price === "") {
      setError(
        `Please enter a valid price between ${min} and ${max} before adding to the cart.`
      );
      return;
    }

    let newQty = cartItemCountNav + quantity;
    if (newQty > 10) {
      setShowAlert(true);
      setAlertMessage("You cannot add more than 10 items!");
      setTimeout(() => setShowAlert(false), 2000);

      return;
    }

    setIsButtonDisabled(true);

    const newItem = {
      sku: product.sku,
      qty: quantity,
      product_price: parseFloat(price),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}cart/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(newItem),
        }
      );

      const data = await response.json();
      if (data.status === 401) {
        navigate("/login");
      }

      
      if (data.status === 200) {
        setShowSuccessAlert(true);
        updateCartCount(cartItemCountNav + quantity);
        setTimeout(() => {
          setShowSuccessAlert(false);
          navigate("/cart");
        }, 2000);
      } else if (
        data.status === 400 &&
        data.message === "Product already exists in cart."
      )
      
      {
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 2000);
        setIsButtonDisabled(false);
        setAlertMessage(data.message);
      } 
      else if ( data.status === 400)
         {
          setShowlimitError(true);
        setTimeout(() => {
          setShowlimitError(false);
        }, 2000);
        setIsButtonDisabled(false);
        setAlertMessage(data.message);
      } 
      
      else if (data.errors.errors && Array.isArray(data.errors)) {
        // Handle array of errors
        const errorMessage = data.errors.errors.map(err => err.msg).join(', ');
        setError(errorMessage || 'Please try again.');
    } else {
      const errorMessage = data.errors.errors.map(err => err.msg).join(', ');
      setError(errorMessage || ' Please try again.');
    }
    setIsButtonDisabled(false);
    } catch (error) {
      // console.error("Error adding item to cart:", error);
      
    }
  };

  // Function to handle quantity changes
  const handleQuantityChange = (change) => {
    if (quantity + change > 10) {
      setShowQtyAlert(true);
      setTimeout(() => {
        setShowQtyAlert(false);
      }, 2000); // Show alert for 2 seconds
    } else {
      setQuantity((prevQty) => Math.max(prevQty + change, 1)); // Ensure quantity does not go below 1
    }
  };

  if (loading) {
    return <Preloader />;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  // const slugify = (title) => {
  //   return title
  //     .toLowerCase()
  //     .replace(/[^a-z0-9\s-]/g, '')  // Remove all special characters
  //     .replace(/\s+/g, '-')           // Replace spaces with hyphens
  //     .replace(/-+/g, '-')            // Ensure no multiple hyphens
  //     .trim();                        // Trim leading/trailing spaces
  // };

  // // Example title to slugify (can be dynamic)
  // const pageTitle = product.name;
  // const pageSlug = slugify(pageTitle);

  return (
    <div className="productdetail mt-5">
          {/* <SEO
      title={pageTitle}
        description="Discover exclusive gifts and offers only on GiftPlus. Unlock the best deals and surprises."
        keywords="GiftPlus, gifts, exclusive offers, deals"
        author="Your Name"
      />
         <p>Page Slug: {pageSlug}</p> */}
      <div className="container">
        <div className="row">
          {showAlert && <AlertRed title={alertMessage} />}
          <div className="product-list p-40 text-center">
            {/* <div className=" "> */}
              <div className="gift-card">
                <div className="row ">
                  <div className="col-md-4 mt-2">
                    <div className="img-pr rib-dtl position-relative">
                      {/* Flat Discount Ribbon */}
                      {product.flat_discount?.value && (
                        <div className="flat-off position-absolute">
                          <div className="ribbon position-relative">
                            <img
                              src={ribbon}
                              alt="Ribbon"
                              className="ribbon-img"
                            />
                            <p className="m-0 p-0 flat-text">
                              {product.flat_discount.value}% Flat off
                            </p>
                          </div>
                        </div>
                      )}

                      {/* Product Image */}
                      <div className="product-image text-center">
                        <img
                          src={product.thumbnail || amazonImg}
                          alt={product.name || "Product"}
                          className="rounded"
                          style={{ width: "80%", height: "auto" }}
                        />
                      </div>
           
                    </div>
                  </div>

                  <div
                    className="col-md-8 text-left pl-10"
                    data-aos="fade-right"
                    data-aos-delay="300"
                  >
                    <div className="d-block border-0 text-left ">
                      <div className="d-flex gap-2 mb-1">
                        <p className="expiry-time text-danger f-14 f-weight-600">
                          Expiry {product.expiry}
                        </p>
                      </div>
                      <h3 className="f-24 f-weight-600">{product.name}</h3>
                      <h5 className="f-16 f-weight-600">
                        Brand <strong>{product.brand_name}</strong>
                      </h5>
<div className="br-btm">
<div className="mt-2 col-md-12">
                        <div className="d-flex justify-content-between items-center rounded-2  p-2">
                          <div className="d-flex gap-2 items-center">
                            <FaDownload
                              style={{ color: "#568203", fontSize: "16px" }}
                              className="text-2xl"
                            />
                            <h1 className="font-bold text-3xl text-dark">
                              Instant Delivery
                            </h1>
                          </div>
                          <div
                            className="cursor-pointer px-1"
                            onClick={() => toggleDropdown(1)}
                          >
                            {isOpen === 1 ? (
                              <FaMinus style={{ color: "grey" }} />
                            ) : (
                              <FaPlus style={{ color: "grey" }} />
                            )}
                          </div>
                        </div>
                      </div>
                      {isOpen === 1 && (
                        <div className="px-2 d-flex gap-1 col-md-12 border-1 bg-white py-1 rounded-2 position-relative z-3">
                         
                          <div
                            className="font-bold relative z-3"
                            style={{ fontSize: "10" }}
                          >
                            You can instantly deliver the product details to
                            multiple channels such as email, SMS, and WhatsApp.
                            This ensures a smooth and fast transaction process.
                          </div>
                        </div>
                      )}
</div>
                    
<div className="br-btm">
<div className="mt-2 col-md-12">
                        <div className="d-flex justify-content-between items-center rounded-2  p-2">
                          <div className="d-flex gap-2 items-center ">
                            <FaArrowRightArrowLeft
                              style={{ color: "#CC5500", fontSize: "16" }}
                              className="text-4xl"
                            />
                            <h4 className="text-dark font-bold text-3xl">10 days Money-Back Guaranteed</h4>
                          </div>
                          <div
                            className="cursor-pointer px-1"
                            onClick={() => toggleDropdown(2)}
                          >
                            {isOpen === 2 ? (
                              <FaMinus style={{ color: "grey" }} />
                            ) : (
                              <FaPlus style={{ color: "grey" }} />
                            )}
                          </div>
                        </div>
                      </div>
                      {isOpen === 2 && (
                        <div className="px-2 d-flex gap-1 col-md-12 border-1 bg-white py-1 rounded-2 position-relative z-3">
                     
                          <p className="font-bold text-lg">
                            Full refund guaranteed if we are unable to resolve
                            any technical issues with sending the card within a
                            few days.
                          </p>
                        </div>
                      )}
</div>

<div className="br-btm-2">
<div className="mt-1 col-md-12">
                        <div className="d-flex justify-content-between items-center rounded-2  p-2">
                          <div className="d-flex gap-2 items-center ">
                            <BsPersonHeart
                              style={{ color: "#470080", fontSize: "18" }}
                              className="text-8xl"
                            />
                            <p className="font-bold text-3xl text-dark">
                              Smooth & Secure Payment
                            </p>
                          </div>
                          <div
                            className="cursor-pointer px-1"
                            onClick={() => toggleDropdown(3)}
                          >
                            {isOpen === 3 ? (
                              <FaMinus style={{ color: "grey" }} />
                            ) : (
                              <FaPlus style={{ color: "grey" }} />
                            )}
                          </div>
                        </div>
                      </div>
                      {isOpen === 3 && (
                        <div className="px-2 d-flex gap-1 col-md-12 border-1 bg-white  py-1 rounded-2 position-relative z-3">
                          <p className="font-bold text-xl">
                            The payment process is very quick. Discover the
                            power of our secure [gift].
                          </p>
                        </div>
                      )}
</div>                   
                    </div>
                  </div>
                </div>

                {showLoginAlert && <AlertRed title="Please login first!" />}
                {showSuccessAlert && <Alert title="Item is adding to cart!!" />}
                {showQtyAlert && (
                  <AlertRed title="You can only add up to 10 items!" />
                )}
                {ShowError && (
                  <AlertRed title="Product already exists in cart!" />
                )}
{ShowlimitError && (
                  <AlertRed title={alertMessage ||"max limit"} />
                )}
               
                  <div className="row">
                  <div className="col-md-12 text-left mt-3">
<p className="mt-2 st-p">Select Price</p>
                    {product.price.denominations?.length > 0 ? (
                      <div className="denomination-tabs mb-3">
                        {product.price.denominations.map((denomination) => (
                          <button
                            key={denomination}
                            className={`btn denomination-btn ${
                              selectedDenomination === denomination
                                ? "active"
                                : ""
                            }`}
                            onClick={() =>
                              handleDenominationSelect(denomination)
                            }
                          >
                            ₹{denomination}
                          </button>
                        ))}
                      </div>
                    ) : (
                      <div className="denomination-tabs mb-1">
                        {[
                          500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500,
                          5000,
                        ].map((customDenomination) => (
                          <button
                            key={customDenomination}
                            className={`btn denomination-btn ${
                              selectedDenomination === customDenomination
                                ? "active"
                                : ""
                            }`}
                            onClick={() =>
                              handleDenominationSelect(customDenomination)
                            }
                          >
                            ₹{customDenomination}
                          </button>
                        ))}
                      </div>
                    )}

{
  product.price.type === "RANGE" && (
    <div className="pricerange">
    <Input
      placeholdertext={`Enter your Price Range min ${min} to max ${max}`|| "Enter your Price "}
      name="price_range"
      labeltext="Price"
      fortext="price_range"
      typetext="number"
      value={price}
      onChange={handlePriceChange}
      maxLength={5}
      error={error}
      className="py-1 m-0"
    />
  </div>

  )
}
                  
</div>
<div className="col-md-5 "> 
<div className="d-flex flex-row align-items-center mt-1 gap-5 ml-5">
                       
                       <div className=" text-left qty-main gap-2 align-items-center mb-2">
                         <p className="f-16 mb-1 f-weight-600">Quantity</p>
                         <div className="d-flex align-items-center qty">
                           <button
                             className="btn btn-secondary"
                             onClick={() => handleQuantityChange(-1)}
                           >
                             -
                           </button>
                           <span className="mx-2">{quantity}</span>
                           <button
                             className="btn btn-secondary-plus"
                             onClick={() => handleQuantityChange(1)}
                           >
                             +
                           </button>
                         </div>
                       </div>

                       {/* Add to Cart Button */}
                       <button
                         className="common-btn addtocart mt-2"
                         disabled={isButtonDisabled}
                         onClick={addToCart}
                       >
                         {isButtonDisabled ? "Processing..." : "Add To Cart"}
                       </button>
                     </div>
</div>
                  </div>

                <div
                  className="accordion accordion-flush mt-3"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        <h2 className="f-20 f-weight-600">Description</h2>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body text-start">
                      <div
                          dangerouslySetInnerHTML={{ __html: product.description }}
                        />       
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        <h2 className="f-20 f-weight-600">
                          Terms & Conditions
                        </h2>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body text-start">
                        <div
                          dangerouslySetInnerHTML={{ __html: product.tnc }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;
