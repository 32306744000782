import React from 'react'
import Commonbtn from '../../Commonbtn/Commonbtn'
import vector from '../../Assets/HowItWorks/vector.png'
import './Getstart.css'
import { Link } from 'react-router-dom'
function Getstart() {
  return (
    <div className='bg-fb text-center p-60 getstart mb-5'>
<div className='container'>
<h3 className='heading f-50'>Get Started Today!</h3>
<p className=' subtext'>Unlock the full potential of gifting with Gifzi! Whether you're a corporate user looking to reward employees or thank clients, or an individual wanting to celebrate special moments with family and friends, Gifzi makes the process simple and meaningful. Register today to get your Gifzi Plus Card and explore a wide selection of brand gift cards. Start personalizing your gifting experience now—it's fast, easy, and thoughtful!</p>
<Link to={`/giftplus`}>
<Commonbtn Commonbtntext="Start Now"  />
</Link>
</div>
{/* <img src={vector} alt='' height="100%" width="100%" /> */}
    </div>
  )
}

export default Getstart