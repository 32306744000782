import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import './Earn.css';
import box from '../Assets/box.png';
import treasure from '../Assets/treasure.svg';
import Input from '../Input/Input';
import Commonbtn from '../Commonbtn/Commonbtn';
import Outline from '../Commonbtn/Outline';

function Earn() {
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate(); // Initialize useNavigate
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    urls: [],
  });
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    const platform = event.target.name;
    if (event.target.checked) {
      setSelectedPlatforms([...selectedPlatforms, platform]); // Add platform to the selected list
    } else {
      setSelectedPlatforms(selectedPlatforms.filter(item => item !== platform)); // Remove platform from the selected list
    }
  };

  // Handle input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Validate email format
  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

  // Validate phone number format
  const isValidPhone = (phone) => /^\d{10}$/.test(phone);

  // Handle submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Input validations
    if (!formData.name || formData.name.trim() === '') {
      setErrorMessage('Name is required and cannot be empty.');
      clearMessage();
      return;
    }

    if (!isValidEmail(formData.email)) {
      setErrorMessage('Please enter a valid email address.');
      clearMessage();
      return;
    }

    if (!isValidPhone(formData.phone)) {
      setErrorMessage('Please enter a valid 10-digit mobile number.');
      clearMessage();
      return;
    }

    const urls = selectedPlatforms.map(platform => {
      const url = formData[platform];
      return { [platform.toLowerCase()]: url };
    }).filter(url => Object.values(url)[0]); // Ensure non-empty URLs

    if (urls.length === 0) {
      setErrorMessage('Please enter at least one valid URL for the selected platforms.');
      clearMessage();
      return;
    }

    const data = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      urls: urls,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}menu/write_earn`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (response.ok) {
        setSuccessMessage('Submission successful!'); // Show success message
        setErrorMessage(''); // Clear any previous error message

        // Reset form data
        setFormData({
          name: '',
          email: '',
          phone: '',
          urls: [],
        });
        setSelectedPlatforms([]);
        clearMessage();

        // Navigate to /getpost after successful submission
        navigate('/getpost');
      } else {
        // Handle validation errors
        setErrorMessage(result.errors.errors.map(err => err.msg).join(' '));
      }
    } catch (error) {
      // console.error('Error:', error);
      setErrorMessage('An error occurred during submission.');
      clearMessage();
    }
  };

  // Function to clear messages after 3 seconds
  const clearMessage = () => {
    setTimeout(() => {
      setSuccessMessage('');
      setErrorMessage('');
    }, 3000); // 3 seconds
  };

  return (
    <div className='earn'>
      <div className='container'>
        <section className='main-form p-60'>
          <div className='d-flex-center'>
            <img src={box} className='Box' height="100px" width="100px" alt="Box" />
            <h1 className='f-40 text-main'>Earn Up to ₹10,000 with Gifzi!</h1>
          </div>

          <div className='row bg-fb form-content shadow-sm'>
            <form onSubmit={handleSubmit}>
              <div className='col-md-12'>
                <Input
                  placeholdertext="Enter your name"
                  labeltext="Name"
                  fortext="name"
                  typetext="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className='col-md-12'>
                <Input
                  placeholdertext="Enter your email"
                  labeltext="Email"
                  fortext="email"
                  typetext="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className='col-md-12'>
                <Input
                  placeholdertext="Enter your phone number"
                  labeltext="Contact Number"
                  fortext="phone"
                  typetext="tel"
                  name="phone"
                  maxLength={10} // Restrict input to 10 digits
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </div>

              <div className="col-md-12 role">
                <p className='f-16 f-weight-600 mb-2'>Platform (where you have posted)</p>
                <div className="d-flex checklist-other">
                  {['Instagram', 'Twitter', 'Facebook', 'Other'].map(platform => (
                    <div className="form-check" key={platform}>
                      <input
                        type="checkbox"
                        name={platform}
                        className="checkbox-round"
                        id={`exampleCheck${platform}`}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label m-0" htmlFor={`exampleCheck${platform}`}>
                        {platform}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {selectedPlatforms.length > 0 && (
                selectedPlatforms.map((platform, index) => (
                  <div key={index} className={`col-md-12 `}>
                    <Input
                      placeholdertext={`Enter the URL for ${platform}`}
                      labeltext={`${platform} Post URL`}
                      fortext="postUrl"
                      typetext="text"
                      name={platform}
                      value={formData[platform] || ''}
                      onChange={handleInputChange}
                    />
                  </div>
                ))
              )}
<div className='d-flex gap-3 mt-4 mob-flex'> 
<Commonbtn Commonbtntext="Submit" />
<Link to="/getpost">

<Outline Outlinebtntext="Recent Post" />
</Link>
</div>

      
            </form>
          </div>

          {/* Show success or error alert */}
          {successMessage && (
            <div className="alert alert-success mt-3">{successMessage}</div>
          )}

          {errorMessage && (
            <div className="alert alertred mt-3">{errorMessage}</div>
          )}

          <img src={treasure} className='treasure' height="100px" width="100px" alt="Treasure" />
        </section>
       
      </div>
      <div>
        <div className="earn-container p-60" id="how-earn">
          <div className="container">
            <h3>Earn Up to ₹10,000 with Gifzi!</h3>
            <p>Love Gifzi? Now, you can earn up to ₹10,000 just by sharing your experience with your friends and followers on social media! It’s simple, fun, and rewarding.</p>

            <div className='how-it-works'>
              <h2>How It Works:</h2>
              <ul>
                <li>
                  <h3>Create a Post:</h3>
                  <p>Share your experience with Gifzi on any of your social media platforms—be it Instagram, Facebook, Twitter, or any other. Show off your favorite Gifzi product, talk about your shopping experience, or share how Gifzi has added value to your life.</p>
                </li>
                <li>
                  <h3>Tag & Hashtag:</h3>
                  <p>Make sure to tag our official account @Gifzi and use the hashtag #GifziRewards in your post so we can find your entry.</p>
                </li>
                <li>
                  <h3>Earn Rewards:</h3>
                  <p>Based on the creativity, engagement, and overall quality of your post, you could earn up to ₹10,000! The more love your post receives, the higher your reward.</p>
                </li>
                <li>
                  <h3>Submit Your Post:</h3>
                  <p>Once your post is live, submit the link to us via the form on this page. Our team will review your entry and notify you if you’ve won.</p>
                </li>
              </ul>
              
          <h2>Tips for Winning:</h2>
          <ul>
            <li>Be creative and authentic in your content.</li>
            <li>Highlight what makes Gifzi special to you.</li>
            <li>Engage your audience with your post—likes, comments, and shares could boost your chances!</li>
          </ul>

          <h2>Terms & Conditions:</h2>
          <ul>
            <li>This offer is open to all Gifzi users with active social media accounts.</li>
            <li>Posts must be public and stay live for at least 30 days to be eligible.</li>
            <li>Rewards will be distributed based on the discretion of the Gifzi team.</li>
            <li>Only one submission per user is allowed.</li>
          </ul>

          <h2>Ready to start sharing and earning?</h2>
          <p>Post about Gifzi today and see how far your creativity can take you!</p>
      
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Earn;
