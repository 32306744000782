import React, { useState , useContext, useEffect}from 'react';
import { useNavigate } from 'react-router-dom';
import icon from '../../Assets/Authentication/icon.svg';
import card from '../../Assets/gifzicard.svg';
import '../Form.css';
import Input from '../../Input/Input';
import Commonbtn from '../../Commonbtn/Commonbtn';
import Logo from '../../Logo/Logo';
import { Link } from 'react-router-dom';
import Password from '../../Input/Password';
import Alert from '../../Alert/Alert'; // Import the Alert component
import { FCMContext } from '../../../context/FCMContext';

import { getToken, messaging} from '../../../firebase';
import Preloader from '../../Preloader/Preloader';

function Form() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // State to control alert visibility
  const [alertMessage, setAlertMessage] = useState(''); // State for alert message
  //const fcmToken = useContext(FCMContext);
  const navigate = useNavigate();

  
  const [isLoading, setIsLoading] = useState(false);

  const handlesubmit = () => {
    // Show loader
   
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Delay for 5 seconds
    setTimeout(() => {
      setIsLoading(false);
      login(); // Call your payment function here
    }, 5000);
  };
  const [fcmToken, setFcmToken] = useState(null);

  
  
// console.log(fcmToken,'sss')
  // Login function to handle API request
  async function login() {
    const item = {
        mobile: phoneNumber,
        password: password,
        fcm_token: fcmToken,
    };
    const ipResponse = await fetch('https://api.ipify.org?format=json');
    const ipData = await ipResponse.json();
    const clientIp = ipData.ip;
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}user/login`, {
            method: 'POST',
            headers: {
              'Client-Address':clientIp,
                'Content-Type': 'application/json',
                'Accept': 'application/json'

            },
            body: JSON.stringify(item),
        });

        const result = await response.json();
        // console.log('response', response);
        // console.log('result', result);

        if (response.ok) {
            // Set loading to true while navigating
            setLoading(true);

            // Save user information in local storage
            localStorage.setItem('user-info', JSON.stringify(result));
            localStorage.setItem('firstName', result.data.first_name);
            localStorage.setItem('lastName', result.data.last_name);
            localStorage.setItem('userEmail', result.data.email);
            localStorage.setItem('userMobile', result.data.mobile);
            localStorage.setItem('authToken', result.data.token);
            localStorage.setItem('userId', result.data.id);

            // Show success alert with an emoji and message
            setAlertMessage('🎉 Login successful! Welcome back to your account.');
            setShowAlert(true);

            // Navigate after a delay to show loader
            setTimeout(() => {
                navigate("/gift-cards"); // Redirect to the gift cards page
                setLoading(false); // Stop loading after redirect
            }, 500); // 0.5-second delay for smoother UX
        } else if (result.errors && Array.isArray(result.errors)) {
            // Handle array of errors
            const errorMessage = result.errors.map(err => err.msg).join(', ');
            setError(errorMessage || 'Login failed. Please try again.');
        } else {
            setError(result.message || 'Login failed. Please try again.');
        }
    } catch (error) {
        setError('An error occurred. Please try again.');
    }
}

  return (
    <section className="authentication">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="form-left">

            {isLoading ? (
               <Preloader />
               // Replace with your loader design
      ) : (
        <>
         <form id="survey-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-head col-md-12">
                    <div className="logo-for desk-none">
                      <Logo />
                    </div>
                    <h1 className="f-40 mb-2">Sign in</h1>
                    <p className="f-18 f-weight-600 greyf6">
                      Don't have an account?{' '}
                      <Link to="/register" className="text-decoration-underline text-main">
                        Create now
                      </Link>
                    </p>
                  </div>

                  <div className="col-md-12">
                    <Input
                      placeholdertext="Enter your registered number"
                      labeltext="Phone Number"
                      fortext="phonenumber"
                      typetext="number"
                      name="mobile"
                      value={phoneNumber}
                      maxLength={10} // Restrict input to 10 digits
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <Password
                      placeholdertext1="Enter your Password"
                      labeltext1="Password"
                      fortext1="Password"
                      typetext1="Password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  {error && (
                    <div className="col-md-12">
                      <p className="error-message">{error}</p>
                    </div>
                  )}
                  <div className="col-md-12 text-end">
                    <Link to="/forgot">
                      <span className="text-main f-16">Forget Password?</span>
                    </Link>
                  </div>
                  <div className="col-md-12 d-flex-center btn-form">
                    <Commonbtn Commonbtntext={loading ? 'Signing in....' : 'Sign in'} onclicktext={handleSubmit} disabled={loading} />
                  </div>
                  <Link to='/'>
                    <div className='col-md-12 d-flex-center align-items-center gap-2 mt-3'>
                      <i className="arrow-right">
                        <svg
                          width="15px"
                          height="15px"
                          viewBox="0 0 18 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_945_2412)">
                            <path
                              d="M1 7.5H17M17 7.5L11 1.5M17 7.5L11 13.5"
                              stroke="black"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_945_2412">
                              <rect
                                width="18"
                                height="14"
                                fill="white"
                                transform="translate(0 0.5)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </i>
                      <p className='f-14 text-grey '>Back To <strong className='text-decoration-underline'> Home</strong></p>
                    </div>
                  </Link>
                </div>
              </form>
        </>
      )}
              
          
              {showAlert && (
  <div className="alert-container">
    <Alert title={alertMessage} />
  </div>
)}

            </div>
          </div>
          <div className="col-md-5 form-wrap">
            <div className="form-right-sec">
              <div className="logo-for">
                <Logo />
              </div>
              <div className="form-right position-relative">
                <h3 className="f-30 f-weight-600 text-main mb-3">E-Gift Vouchers</h3>
                <p className="f-16">
                  Use your gift card around the world with no hidden fees. Hold, transfer, and spend
                  money.
                </p>
                <div className="card-des">
                  <img src={card} alt="logo" data-aos="fade-right" data-aos-delay="200" height="100%" width="100%" />
                </div>
              </div>

              <div className="card-design shadow-lg rounded-2 row d-flex-center text-start p-2">
                <div className="icon col-md-4">
                  <img src={icon} alt="icon" height="100%" width="100%" />
                </div>
                <div className="right col-md-8">
                  <span className="f-14">Gifzi</span>
                  <p className="f-16 f-weight-600 text-lr">Effortless Gifting, Endless Possibilities.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Form;
