import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import giftplus from "../Assets/pluscardgif.svg";
import gifziplusion from "../Assets/gifziplusion.svg";
import "./Banner.css";
import "../Commonbtn/Popup.css";
import Outline from "../Commonbtn/Outline";
import Commonbtn from "../Commonbtn/Commonbtn";
import Alert from "../Alert/Alert";
import Denomination from "./Denomination";

function Banner() {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showDenominationModal, setShowDenominationModal] = useState(false);
  const [selectedDenomination, setSelectedDenomination] = useState("");
  const [customAmount, setCustomAmount] = useState("");
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();

  useEffect(() => {
    let timeoutId;
    if (showAlert) {
      timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // Hide alert after 3 seconds
    }
    return () => clearTimeout(timeoutId); // Cleanup on unmount or alert change
  }, [showAlert]);



  const handleAddCardClick = () => {
    if (!token) {
      setAlertMessage("Please login first to add a card.");
      setShowAlert(true);
      navigate('/login');
      return;
    }

    navigate('/addpluscard');
  };

  const handleGetCardClick = () => {
    if (!token) {
      setAlertMessage("You need to login first to get a Gifzi Plus card.");
      setShowAlert(true);
      navigate('/login');
      return;
    }
    
    setShowDenominationModal(true);
  };

  const handleCloseModal = () => {
    setShowDenominationModal(false);
  };

  const handleDenominationSelect = (denomination) => {
    setSelectedDenomination(denomination);
    setCustomAmount("");
  };

  const handleCustomAmountChange = (e) => {
    const value = e.target.value;
    // Validate the custom amount (between 10 and 100000)
    if (value >= 10 && value <= 100000) {
      setCustomAmount(value);
      setSelectedDenomination(""); // Reset selected denomination
    }
  };

  const handleAddToCart = (amount) => {
    // console.log(`Added ${amount} to cart!`);
    handleCloseModal(); // Close the modal after adding to cart
  };

  return (
    <div className="banner gifzi-plus">
      <div className="container">
        <div className="row p-60 d-flex align-items-center position-relative">
          {showAlert && (
            <Alert title={alertMessage} className="alertred" />
          )}
          <div className="col-md-6 text-left">
            <h1 className="f-70 f-weight-600 mb-3">
              Your All-in-One <strong>Gifting Hub</strong>
            </h1>
            <p className="mb-4 text-content">
              Gifzi Plus is your ultimate solution for all gifting needs,
              designed for both individuals and corporate users. With just one
              platform, you can purchase a wide range of gift cards from leading
              brands across categories like fashion, travel, beauty, and more.
              Whether you're sending a thoughtful gift to friends and family or
              rewarding employees and clients, Gifzi Plus simplifies the entire
              process. It's an easy, efficient, and personalized way to manage
              gifting for every occasion, making thoughtful gestures effortless
              and fun.
            </p>
            <div className="cart-item-card d-flex align-items-center mb-3 gap-3 shadow-sm p-2 rounded-3">
              <i className="mr-3 prtimg">
                <img
                  src={gifziplusion}
                  alt="product"
                  height="100px"
                  width="150px"
                />
              </i>
              <div className="cart-content col-lg-6 col-md-12 col-sm-12">
                <p className="f-20">Gifzi Plus</p>
              </div>
            </div>
            <div className="d-flex gap-2">
              {token ? (
                <div>
                  <Commonbtn Commonbtntext="Get Gifzi Plus Card" onclicktext={handleGetCardClick} />
                </div>
              ) : (
                <button className="common-btn" onClick={() => navigate('/login')}>
                  Get Gifzi Card
                </button>
              )}
              <div onClick={handleAddCardClick}>
                <Outline Outlinebtntext="Add Card" />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <img
              className="rounded"
              src={giftplus}
              alt="image"
              height="100%"
              width="100%"
            />
          </div>
        </div>
      </div>

      {/* Modal Popup */}
      {showDenominationModal && (
        <Denomination
          onClose={handleCloseModal}
          onAddToCart={handleAddToCart}
          selectedDenomination={selectedDenomination}
          customAmount={customAmount}
          handleDenominationSelect={handleDenominationSelect}
          handleCustomAmountChange={handleCustomAmountChange}
        />
      )}
    </div>
  );
}

export default Banner;
