import React, { useContext, useEffect, useState } from "react";
import "./Product.css";
import SearchBar from "../../Searchbar/Searchbar";
import CategoryTab from "../CategoryTab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useInView } from "react-intersection-observer";
import { Link, useNavigate } from "react-router-dom";
import ribbon from "../../Assets/Ribbon.svg";
import Slider from "react-slick";
import defaulimage from "../../Assets/Category/all.png";
import amazonImg from "../../Assets/logo.png";  
function Product() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [isFilteropen, setIsFilterOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState(null); // Track active filter
  const [minDiscount, setMinDiscount] = useState(null);
  const [maxDiscount, setMaxDiscount] = useState(null);
  const limit = 99999999;
  const { ref, inView } = useInView({ threshold: 1, triggerOnce: false });
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;

  const fetchDiscountedProducts = async (min, max, offset, limit) => {
    try {
      const endpoint = `${baseUrl}product/discount?min=${min}&max=${max}&offset=${offset}&limit=${limit}`;
      const response = await fetch(endpoint, {
        headers: { "Content-Type": "application/json" },
      });
      const jsonData = await response.json();
      if (jsonData.status === 200) {
        return jsonData.data?.products || [];
      }
      return [];
    } catch (err) {
      // console.error("Error fetching discounted products:", err);
      return [];
    }
  };

  // Updated fetchProductData function
  const fetchProductData = async (newOffset, query) => {
    try {
      const endpoint =
        query.trim() === ""
          ? `${baseUrl}product/all?offset=${newOffset}&limit=${limit}`
          : `${baseUrl}product/search?offset=${newOffset}&limit=${limit}&search=${encodeURIComponent(
              query
            )}`;

      const response = await fetch(endpoint, {
        headers: { "Content-Type": "application/json" },
      });
      const jsonData = await response.json();
      if (jsonData.status === 200) {
        return jsonData.data?.products || [];
      }
      return [];
    } catch (err) {
      // console.error("Error fetching product data:", err);
      return [];
    }
  };

  // Search handling
  const handleSearch = async (query) => {
    setSearchQuery(query);
    setProducts([]); // Clear existing products
    setOffset(0);
    setHasMore(true);
    setIsEmpty(false);

    const searchResults = await fetchProductData(0, query);
    if (searchResults.length === 0) {
      setIsEmpty(true);
    } else {
      setProducts(searchResults);
      if (searchResults.length < limit) setHasMore(false);
    }
  };

  // Effect for fetching search results when searchQuery changes
  useEffect(() => {
    if (searchQuery.trim() !== "") {
      (async () => {
        const searchResults = await fetchProductData(0, searchQuery);
        setProducts(searchResults);
        if (searchResults.length === 0) setIsEmpty(true);
        if (searchResults.length < limit) setHasMore(false);
      })();
    }
  }, [searchQuery]);

  // Pagination handling during infinite scroll
  useEffect(() => {
    if (inView && hasMore && searchQuery.trim() === "") {
      (async () => {
        const moreProducts = await fetchProductData(offset, searchQuery);
        setProducts((prevProducts) => [...prevProducts, ...moreProducts]);
        if (moreProducts.length < limit) setHasMore(false);
      })();
      setOffset((prevOffset) => prevOffset + 4); // Adjust offset for next batch
    }
  }, [inView, hasMore, offset, searchQuery]);

  const initialApiUrl =
    "https://api.gifzi.in/api/product/all?offset=0&limit=99999999";

  const fetchInitialProducts = async () => {
    try {
      const response = await fetch(initialApiUrl, {
        headers: { "Content-Type": "application/json" },
      });
      const jsonData = await response.json();
      if (jsonData.status === 200) {
        return jsonData.data?.products || [];
      }
      return [];
    } catch (err) {
      // console.error("Error fetching initial products:", err);
      return [];
    }
  };

  const handleDiscountFilter = async (min, max) => {
    setActiveCategory(0);
    setActiveFilter({ min, max });
    setMinDiscount(min);
    setMaxDiscount(max);
    setProducts([]);
    setIsEmpty(false);
    setHasMore(true);

    const discountedProducts = await fetchDiscountedProducts(
      min,
      max,
      0,
      limit
    );
    if (discountedProducts.length === 0) {
      setIsEmpty(true);
    } else {
      setProducts(discountedProducts);
      if (discountedProducts.length < limit) setHasMore(false);
    }
  };

  const handleRemoveFilter = async () => {
    setActiveCategory(0);
    setActiveFilter(null);
    setMinDiscount(null);
    setMaxDiscount(null);
    setProducts([]);
    setIsEmpty(false);
    setHasMore(true);

    const allProducts = await fetchInitialProducts();
    if (allProducts.length === 0) {
      setIsEmpty(true);
    } else {
      setProducts(allProducts);
      if (allProducts.length < limit) setHasMore(false);
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      const initialProducts = await fetchInitialProducts();
      setProducts(initialProducts);
      if (initialProducts.length < limit) setHasMore(false);
      if (initialProducts.length === 0) setIsEmpty(true);
    };

    loadInitialData();
  }, []);

  useEffect(() => {
    fetchProductData(0, ""); // Initial load
  }, []);

  React.useEffect(() => {
    if (searchQuery.trim() !== "") {
      setOffset(0);
      setProducts([]);
      fetchProductData(0, searchQuery, minDiscount, maxDiscount);
    }
  }, [searchQuery, minDiscount, maxDiscount]);

  React.useEffect(() => {
    if (inView && hasMore && searchQuery.trim() === "") {
      fetchProductData(offset, searchQuery, minDiscount, maxDiscount);
      setOffset((prevOffset) => prevOffset + 4);
    }
  }, [inView, hasMore, offset, searchQuery, minDiscount, maxDiscount]);

  // const handleSearch = (query) => {
  //   setSearchQuery(query);
  //   setProducts([]);
  //   setOffset(0);
  //   setHasMore(true);
  //   setIsEmpty(false);
  // };

  const addToCart = (item) => {
    const storedItems = localStorage.getItem("cartItems");
    const currentItems = storedItems ? JSON.parse(storedItems) : [];
    localStorage.setItem("cartItems", JSON.stringify([...currentItems, item]));
  };

  const handlefilterdropdown = () => setIsFilterOpen(!isFilteropen);

  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const baseUrl = `${process.env.REACT_APP_BASE_API_URL}category/all?offset=0&limit=${limit}`;
    try {
      const response = await fetch(baseUrl, {
        headers: { "Content-Type": "application/json" },
      });
      const jsonData = await response.json();

      const defaultCategory = {
        id: 0,
        name: "All Categories",
        thumbnail: defaulimage,
      };
      setCategories([defaultCategory, ...(jsonData.data?.categories || [])]);

      if (jsonData.status === 200) {
        setCategories([defaultCategory, ...(jsonData.data?.categories || [])]);
      }
    } catch (err) {
      // console.error("Error fetching categories:", err);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchCategories();
  }, []);

  const [activeCategory, setActiveCategory] = useState(0);

  const handleCategoryClick = async (categoryId) => {
    try {
      setActiveCategory(categoryId);
      setProducts([]); // Clear previous products
      setIsEmpty(false);
      setHasMore(true);
  
      let fetchedProducts = [];
  
      if (categoryId === 0) {
        // Call fetchInitialProducts for the default category (id = 0)
        fetchedProducts = await fetchInitialProducts();
      } else {
        // Fetch products for the selected category
        const response = await fetch(
          `${baseUrl}product/category?category_id=${categoryId}&offset=0&limit=${limit}`,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        const jsonData = await response.json();
  
        if (jsonData.status === 200) {
          fetchedProducts = jsonData.data?.products || [];
        }
      }
  
      setProducts(fetchedProducts);
      if (fetchedProducts.length < limit) setHasMore(false);
      if (fetchedProducts.length === 0) setIsEmpty(true);
    } catch (err) {
      // console.error("Error fetching products by category:", err);
    }
  };
  
  // Add a function to extract the category ID from the URL
  const getCategoryFromUrl = () => {
    const url = window.location.href;
    const parts = url.split("?");
    if (parts.length > 1 && !isNaN(parts[1])) {
      return parseInt(parts[1], 10); // Return the numeric ID
    }
    return null; // Return null if no valid ID
  };
  
  // Automatically set the category based on the URL on component mount
  useEffect(() => {
    const initialCategoryId = getCategoryFromUrl() || 0; // Default to 0 if no ID in URL
    handleCategoryClick(initialCategoryId);
  }, []);
  

  const NextArrow = ({ onClick }) => (
    <div className="slick-next" onClick={onClick}>
      &#10095;
    </div>
  );
  const PrevArrow = ({ onClick }) => (
    <div className="slick-prev" onClick={onClick}>
      &#10094;
    </div>
  );
  const categorySliderSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    loop: true,
    slidesToShow: 6,
    slidesToScroll: 3,
    autoplay: false,
    speed: 1000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 4, slidesToScroll: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 3, slidesToScroll: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 3, slidesToScroll: 2 } },
    ],
  };

  const handleRemoveCategoryFilter = async () => {
    setActiveCategory("All"); // Reset to 'All' or a default category
    setProducts([]);
    setIsEmpty(false);
    setHasMore(true);

    // Fetch all products (or reset the category filter)
    fetchProductData(0, searchQuery, minDiscount, maxDiscount);
    setActiveFilter(null);
    setMinDiscount(null);
    setMaxDiscount(null);
    setProducts([]);
    setIsEmpty(false);
    setHasMore(true);

    const allProducts = await fetchInitialProducts();
    if (allProducts.length === 0) {
      setIsEmpty(true);
    } else {
      setProducts(allProducts);
      if (allProducts.length < limit) setHasMore(false);
    }
  };
  // console.log(categories,'ter')
  return (
    <div className="product-list inside-landing p-60 text-center bg-fb main-landing">
      <div className="container">
      <div className="p-30 container">
  {isLoading ? (
    <p>Loading categories...</p>
  ) : (
    <>
      <h3 className="title">Category</h3>
      <Slider {...categorySliderSettings}>
        {/* Category items */}
        {categories.map((category, index) => (
          <div className="main-tab" key={index}>
            <div
              className={`tab-list d-flex-center ${
                activeCategory === category.id ? "active" : ""
              }`}
              onClick={() => handleCategoryClick(category.id)}
            >
              <img
                className="rounded"
                src={category.thumbnail}
                alt={category.name}
                height="100px"
                width="100px"
              />
              <p className="f-14 f-weight-600">{category.name}</p>
            </div>
          </div>
        ))}
      </Slider>

      {/* Tab detail */}
      {activeCategory !== null && (
        <div className="tab-detail d-flex">
          <div className="content">
            <h2 className="f-24 mb-2 f-weight-600">
              {categories.find((cat) => cat.id === activeCategory)?.name}
            </h2>
            <p className="f-14 text-grey"></p>
          </div>
          <div className="cat-tab-img">
            <i className="icon-cat ">
              <img
                className="rounded"
                src={
                  categories.find((cat) => cat.id === activeCategory)?.thumbnail
                }
                alt={
                  categories.find((cat) => cat.id === activeCategory)?.name
                }
                height="100%"
                width="100%"
              />
            </i>
          </div>
        </div>
      )}
    </>
  )}
</div>

        <div className="col-lg-12 col-md-12 col-sm-12 text-right mb-3"></div>

        <h3 className="title">Gift Cards and Gift Vouchers</h3>
        <div className="row">
          <div className="col-lg-10 col-md-10 col-sm-6 col-7 mb-4 search-gift-card">
            <SearchBar
              searchtext="Search Here"
              searchtype="text"
              onSearch={handleSearch}
            />
          </div>
          <div className="col-lg-2 col-md-2 col-sm-6 col-4 filter-mob position-relative">
            <button
              className="common-btn d-flex"
              onClick={handlefilterdropdown}
            >
              <FontAwesomeIcon icon={faFilter} className="text-white" /> Filter
            </button>
            {isFilteropen && (
              <div className="filter-dropdown bg-white w-full position-absolute z-3 shadow-md col-md-12 py-3 px-2">
                <ul className="p-0 m-0 text-left">
                  <li className="bg-grey mb-2">
                    <span className="f-13 text-500-grey">Discount</span>
                  </li>
                  <li className="text-left">
                    <button
                      onClick={() => handleDiscountFilter(1, 5)}
                      className="w-full text-left"
                    >
                      <p
                        className="text-500-grey font-semibold text-2xl"
                        style={{
                          color: "rgb(162, 162, 163)",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        1% to 5%
                      </p>
                    </button>
                  </li>
                  <hr
                    style={{
                      borderTop: "2px solid rgb(141, 141, 147)",
                      margin: "8px 0",
                    }}
                  />
                  <li className="text-left">
                    <button
                      onClick={() => handleDiscountFilter(6, 10)}
                      className="w-full text-left"
                    >
                      <p
                        className="text-500-grey font-semibold text-2xl"
                        style={{
                          color: "rgb(162, 162, 163)",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        6% to 10%
                      </p>
                    </button>
                  </li>
                  <hr
                    style={{
                      borderTop: "2px solid rgb(141, 141, 147)",
                      margin: "8px 0",
                    }}
                  />
                  <li className="text-left">
                    <button
                      onClick={() => handleDiscountFilter(10, 100)}
                      className="w-full text-left"
                    >
                      <p
                        className="text-500-grey font-semibold text-2xl"
                        style={{
                          color: "rgb(162, 162, 163)",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        10% & more
                      </p>
                    </button>
                  </li>
                  {activeFilter && (
                    <li className="text-left mt-3">
                      <button
                        onClick={handleRemoveFilter}
                        className="w-full text-left"
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-danger"
                        />{" "}
                        Remove Filter
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
          {isEmpty ? (
            <div className="no-data">No data available</div>
          ) : (
            products.map((item) => (
              <div
                key={item.sku}
                className="col-md-6 col-lg-3 col-sm-12 gift-card d-flex"
              >
                <Link
                  to={`/productdetail/${item.sku}`}
                  className="d-block w-100"
                >
                  <div className="card d-block border-0">
                   
                    {item.flat_discount && (
                      <i className="flat-off position-absolute">
                        <div className="ribbon position-relative">
                          <img
                            src={ribbon}
                            height="100%"
                            width="100%"
                            alt="Ribbon"
                          />
                          <p className="m-0 p-0 flat-text">
                            {item.flat_discount}% Flat off
                          </p>
                        </div>
                      </i>
                    )}
                    <i className="product-image">
                      <img
                        className="rounded"
                        height="100%"
                        width="100%"
                        src={
                          item.thumbnail ||
                          amazonImg
                        }
                        alt="Product"
                      />
                    </i>
                    <h3 className="product-name">{item?.name}</h3>
                  </div>
                </Link>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default Product;
