import React, { useState, useEffect } from "react";
import "./Category.css";
import amazonImg from "../Assets/amazone.png";
import { Link } from "react-router-dom";
import SearchBar from "../Searchbar/Searchbar";

function Viewall() {
  const [categories, setCategories] = useState([]);
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`; // Define the base URL

  useEffect(() => {
    // Fetch all categories initially
    fetchCategories("");
  }, []);

  const fetchCategories = (query) => {
    // Build the endpoint URL based on whether query is empty or not
    const endpoint =
      query.trim() === ""
        ? `${baseUrl}category/all?offset=0&limit=10`
        : `${baseUrl}category/search?offset=0&limit=10&search=${encodeURIComponent(
            query
          )}`;

    fetch(endpoint)
      .then((response) => response.json())
      .then((data) => {
        // console.log("API response:", data.data?.categories); // Debugging: check the API response
        setCategories(
          Array.isArray(data.data?.categories) ? data.data?.categories : []
        );
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  return (
    <div>
      <div className="product-list p-80 text-center bg-fb">
        <div className="container">
          <div className="row">
            <h3 className="title">Categories List</h3>
            <div className="col-md-12">
              <SearchBar
                searchtext="Search Here"
                searchtype="text"
                searchclass="shadow-sm mt-3 mb-3"
                onSearch={fetchCategories} // Passing fetchCategories as onSearch
              />
            </div>
            {Array.isArray(categories) && categories.length > 0 ? (
              categories.map((category, index) => (
                <div
                  className="col-md-4 gift-card"
                  key={index}
                  data-aos="fade-right"
                  data-aos-delay="300"
                >
                  <Link to="/" className="card d-block border-0">
                    <h3 className="product-name">{category.name}</h3>
                    <img
                      style={{ boxShadow: "0px 1px 8px 1px #473594" }}
                      src={amazonImg}
                      alt="category"
                      className="rounded"
                    />
                    <h3
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "1rem",
                        marginTop: "0.8rem",
                        color: "#666666",
                      }}
                    >
                      {category.name}
                    </h3>
                    <Link to="/" className="f-16 buy-text d-block">
                      BUY NOW AT RS.2000
                    </Link>
                  </Link>
                </div>
              ))
            ) : (
              <p>No categories available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Viewall;
