import React from "react";
function Commonbtn(props) {
  return (
    <div>
      <button className="common-btn" onClick={props.onclicktext} type="submit">
        {" "}
        {props.Commonbtntext}{" "}
      </button>
    </div>
  );
}

export default Commonbtn;
